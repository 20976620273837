/*==========================================================
 1. IMPORT FILES
 ==========================================================*/
/*==========================================================
 2. GENERAL
 ==========================================================*/
html {
  border: 0;
  font-size: 100%;
  margin: 0;
  outline: 0;
  padding: 0;
  vertical-align: baseline;
}
body {
  border: 0;
  font-size: 100%;
  margin: 0;
  outline: 0;
  padding: 0;
  vertical-align: baseline;
  font-size: 16px;
  color: #5d5d5d;
  font-weight: 400;
  font-family: 'Jost-Book';
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
}
.my-nav {
  background-color: #ec1c38;
  text-transform: uppercase;
  font-size: 15px;
  font-weight: 500;
  font-family: Jost;
  padding-bottom: 24px;
}
.my-link {
  display: inline-block;
  height: 20px;
  color: white;
}
.my-link:hover {
  color: yellow;
}

.my-link:active {
  color: white;
}
/* .my-nav .navbar-text {
  color: black;
} */
.my-h3 {
  font-size: 15px;
  font-weight: 500;
  line-height: 1.6;
  color: #5d5d5d;
  text-transform: uppercase;
  margin-bottom: 6px;
}
.my-p {
  font-size: 16px;
  line-height: 1.5;
  margin-bottom: 10px;
  margin-right: 5px;
  display: inline-block;
  color: #5d5d5d;
  font-weight: 400;
  font-family: 'Jost';
}
.my-p1 {
  font-size: 13px;
  line-height: 1.3;
  margin-bottom: 5px;
  margin-right: 5px;
  display: inline-block;
  color: #5d5d5d;
  font-weight: 400;
  font-family: 'Jost';
}
div {
  border: 0;
  font-size: 100%;
  margin: 0;
  outline: 0;
  padding: 0;
  vertical-align: baseline;
}
span {
  border: 0;
  font-size: 100%;
  margin: 0;
  outline: 0;
  padding: 0;
  vertical-align: baseline;
}
applet {
  border: 0;
  font-size: 100%;
  margin: 0;
  outline: 0;
  padding: 0;
  vertical-align: baseline;
}
object {
  border: 0;
  font-size: 100%;
  margin: 0;
  outline: 0;
  padding: 0;
  vertical-align: baseline;
}
iframe {
  border: 0;
  font-size: 100%;
  margin: 0;
  outline: 0;
  padding: 0;
  vertical-align: baseline;
}
h1 {
  border: 0;
  font-size: 100%;
  margin: 0;
  outline: 0;
  padding: 0;
  vertical-align: baseline;
  clear: both;
  color: #2d2d2d;
  font-family: 'Jost';
  font-weight: 700;
  font-size: 24px;
}
h2 {
  border: 0;
  font-size: 100%;
  margin: 0;
  outline: 0;
  padding: 0;
  vertical-align: baseline;
  clear: both;
  color: #2d2d2d;
  font-family: 'Jost';
  font-weight: 700;
  font-size: 20px;
}
h3 {
  border: 0;
  font-size: 100%;
  margin: 0;
  outline: 0;
  padding: 0;
  vertical-align: baseline;
  clear: both;
  color: #2d2d2d;
  font-family: 'Jost';
  font-weight: 700;
  font-size: 18px;
}
h4 {
  border: 0;
  font-size: 100%;
  margin: 0;
  outline: 0;
  padding: 0;
  vertical-align: baseline;
  clear: both;
  color: #2d2d2d;
  font-family: 'Jost';
  font-weight: 700;
  font-size: 16px;
}
h5 {
  border: 0;
  font-size: 100%;
  margin: 0;
  outline: 0;
  padding: 0;
  vertical-align: baseline;
  clear: both;
  color: #2d2d2d;
  font-family: 'Jost';
  font-weight: 700;
  font-size: 14px;
}
h6 {
  border: 0;
  font-size: 100%;
  margin: 0;
  outline: 0;
  padding: 0;
  vertical-align: baseline;
  clear: both;
  color: #2d2d2d;
  font-family: 'Jost';
  font-weight: 700;
  font-size: 12px;
}
p {
  border: 0;
  font-size: 100%;
  margin: 0;
  outline: 0;
  padding: 0;
  line-height: 1.44;
  vertical-align: baseline;
}
blockquote {
  border: 0;
  font-size: 100%;
  margin: 0;
  outline: 0;
  padding: 0;
  vertical-align: baseline;
}
pre {
  border: 0;
  font-size: 100%;
  margin: 0;
  outline: 0;
  padding: 0;
  vertical-align: baseline;
}
a {
  border: 0;
  font-size: 100%;
  margin: 0;
  outline: 0;
  padding: 0;
  vertical-align: baseline;
  color: #2d2d2d;
  text-decoration: none;
  transition: all 0.3s ease-in-out 0s;
  -moz-transition: all 0.3s ease-in-out 0s;
  -o-transition: all 0.3s ease-in-out 0s;
  -webkit-transition: all 0.3s ease-in-out 0s;
  -ms-transition: all 0.3s ease-in-out 0s;
}
a:hover {
  color: #1d1d55;
  text-decoration: none;
}
a:focus {
  text-decoration: none;
  color: inherit;
  outline: none;
}
abbr {
  border: 0;
  font-size: 100%;
  margin: 0;
  outline: 0;
  padding: 0;
  vertical-align: baseline;
}
acronym {
  border: 0;
  font-size: 100%;
  margin: 0;
  outline: 0;
  padding: 0;
  vertical-align: baseline;
}
address {
  border: 0;
  font-size: 100%;
  margin: 0;
  outline: 0;
  padding: 0;
  vertical-align: baseline;
}
big {
  border: 0;
  font-size: 100%;
  margin: 0;
  outline: 0;
  padding: 0;
  vertical-align: baseline;
}
cite {
  border: 0;
  font-size: 100%;
  margin: 0;
  outline: 0;
  padding: 0;
  vertical-align: baseline;
}
code {
  border: 0;
  font-size: 100%;
  margin: 0;
  outline: 0;
  padding: 0;
  vertical-align: baseline;
}
del {
  border: 0;
  font-size: 100%;
  margin: 0;
  outline: 0;
  padding: 0;
  vertical-align: baseline;
}
dfn {
  border: 0;
  font-size: 100%;
  margin: 0;
  outline: 0;
  padding: 0;
  vertical-align: baseline;
}
em {
  border: 0;
  font-size: 100%;
  margin: 0;
  outline: 0;
  padding: 0;
  vertical-align: baseline;
}
font {
  border: 0;
  font-size: 100%;
  margin: 0;
  outline: 0;
  padding: 0;
  vertical-align: baseline;
}
ins {
  border: 0;
  font-size: 100%;
  margin: 0;
  outline: 0;
  padding: 0;
  vertical-align: baseline;
}
kbd {
  border: 0;
  font-size: 100%;
  margin: 0;
  outline: 0;
  padding: 0;
  vertical-align: baseline;
}
q {
  border: 0;
  font-size: 100%;
  margin: 0;
  outline: 0;
  padding: 0;
  vertical-align: baseline;
}
s {
  border: 0;
  font-size: 100%;
  margin: 0;
  outline: 0;
  padding: 0;
  vertical-align: baseline;
}
samp {
  border: 0;
  font-size: 100%;
  margin: 0;
  outline: 0;
  padding: 0;
  vertical-align: baseline;
}
small {
  border: 0;
  font-size: 100%;
  margin: 0;
  outline: 0;
  padding: 0;
  vertical-align: baseline;
}
strike {
  border: 0;
  font-size: 100%;
  margin: 0;
  outline: 0;
  padding: 0;
  vertical-align: baseline;
}
strong {
  border: 0;
  font-size: 100%;
  margin: 0;
  outline: 0;
  padding: 0;
  vertical-align: baseline;
}
sub {
  border: 0;
  font-size: 100%;
  margin: 0;
  outline: 0;
  padding: 0;
  vertical-align: baseline;
}
sup {
  border: 0;
  font-size: 100%;
  margin: 0;
  outline: 0;
  padding: 0;
  vertical-align: baseline;
}
tt {
  border: 0;
  font-size: 100%;
  margin: 0;
  outline: 0;
  padding: 0;
  vertical-align: baseline;
}
var {
  border: 0;
  font-size: 100%;
  margin: 0;
  outline: 0;
  padding: 0;
  vertical-align: baseline;
}
dl {
  border: 0;
  font-size: 100%;
  margin: 0;
  outline: 0;
  padding: 0;
  vertical-align: baseline;
}
dt {
  border: 0;
  font-size: 100%;
  margin: 0;
  outline: 0;
  padding: 0;
  vertical-align: baseline;
}
dd {
  border: 0;
  font-size: 100%;
  margin: 0;
  outline: 0;
  padding: 0;
  vertical-align: baseline;
}
ol {
  border: 0;
  font-size: 100%;
  margin: 0;
  outline: 0;
  padding: 0;
  vertical-align: baseline;
}
ul {
  border: 0;
  font-size: 100%;
  margin: 0;
  outline: 0;
  padding: 0;
  vertical-align: baseline;
}
li {
  border: 0;
  font-size: 100%;
  margin: 0;
  outline: 0;
  padding: 0;
  vertical-align: baseline;
}
fieldset {
  border: 0;
  font-size: 100%;
  margin: 0;
  outline: 0;
  padding: 0;
  vertical-align: baseline;
}
form {
  border: 0;
  font-size: 100%;
  margin: 0;
  outline: 0;
  padding: 0;
  vertical-align: baseline;
}
label {
  border: 0;
  font-size: 100%;
  margin: 0;
  outline: 0;
  padding: 0;
  vertical-align: baseline;
}
legend {
  border: 0;
  font-size: 100%;
  margin: 0;
  outline: 0;
  padding: 0;
  vertical-align: baseline;
}
table {
  border: 0;
  font-size: 100%;
  margin: 0;
  outline: 0;
  padding: 0;
  vertical-align: baseline;
}
caption {
  border: 0;
  font-size: 100%;
  margin: 0;
  outline: 0;
  padding: 0;
  vertical-align: baseline;
}
tbody {
  border: 0;
  font-size: 100%;
  margin: 0;
  outline: 0;
  padding: 0;
  vertical-align: baseline;
}
tfoot {
  border: 0;
  font-size: 100%;
  margin: 0;
  outline: 0;
  padding: 0;
  vertical-align: baseline;
}
thead {
  border: 0;
  font-size: 100%;
  margin: 0;
  outline: 0;
  padding: 0;
  vertical-align: baseline;
}
tr {
  border: 0;
  font-size: 100%;
  margin: 0;
  outline: 0;
  padding: 0;
  vertical-align: baseline;
}
th {
  border: 0;
  font-size: 100%;
  margin: 0;
  outline: 0;
  padding: 0;
  vertical-align: baseline;
}
td {
  border: 0;
  font-size: 100%;
  margin: 0;
  outline: 0;
  padding: 0;
  vertical-align: baseline;
}
img {
  border: none;
  max-width: 100%;
  height: auto;
}
button {
  transition: all 0.3s ease-in-out 0s;
  -moz-transition: all 0.3s ease-in-out 0s;
  -o-transition: all 0.3s ease-in-out 0s;
  -webkit-transition: all 0.3s ease-in-out 0s;
  -ms-transition: all 0.3s ease-in-out 0s;
  border-color: #1d1d55;
  outline: none;
}
input {
  transition: all 0.3s ease-in-out 0s;
  -moz-transition: all 0.3s ease-in-out 0s;
  -o-transition: all 0.3s ease-in-out 0s;
  -webkit-transition: all 0.3s ease-in-out 0s;
  -ms-transition: all 0.3s ease-in-out 0s;
}
svg {
  transition: all 0.3s ease-in-out 0s;
  -moz-transition: all 0.3s ease-in-out 0s;
  -o-transition: all 0.3s ease-in-out 0s;
  -webkit-transition: all 0.3s ease-in-out 0s;
  -ms-transition: all 0.3s ease-in-out 0s;
}
input[type='text'] {
  border: 1px solid #5d5d5d;
  padding: 8px 15px;
  font-size: 14px;
  color: #757575;
  font-style: italic;
  border-radius: 0;
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
}
input[type='text']:focus {
  border-color: #1d1d55;
  outline: none;
}
input[type='email'] {
  border: 1px solid #5d5d5d;
  padding: 8px 15px;
  font-size: 14px;
  color: #757575;
  font-style: italic;
  border-radius: 0;
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
}
input[type='email']:focus {
  border-color: #1d1d55;
  outline: none;
}
input[type='password'] {
  border: 1px solid #5d5d5d;
  padding: 8px 15px;
  font-size: 14px;
  color: #757575;
  font-style: italic;
  border-radius: 0;
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
}
input[type='password']:focus {
  border-color: #1d1d55;
  outline: none;
}
input[type='number'] {
  border: 1px solid #5d5d5d;
  padding: 8px 15px;
  font-size: 14px;
  color: #757575;
  font-style: italic;
  border-radius: 0;
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
}
input[type='number']:focus {
  border-color: #1d1d55;
  outline: none;
}
input[type='search'] {
  border: 1px solid #5d5d5d;
  padding: 8px 15px;
  font-size: 14px;
  color: #757575;
  font-style: italic;
  border-radius: 0;
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
}
input[type='search']:focus {
  border-color: #1d1d55;
  outline: none;
}
textarea {
  border: 1px solid #5d5d5d;
  padding: 8px 15px;
  font-size: 14px;
  color: #757575;
  font-style: italic;
  border-radius: 0;
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
}
textarea:focus {
  border-color: #1d1d55;
  outline: none;
}
.offset-item {
  opacity: 0;
  visibility: hidden;
  transform: translate3d(0, 30px, 0);
  -webkit-transform: translate3d(0, 30px, 0);
  transition: all 1s ease-in-out 0s;
  -webkit-transition: all 1s ease-in-out 0s;
}
.offset-item.animate {
  opacity: 1;
  visibility: visible;
  transform: none;
  -webkit-transform: none;
  transition: all 1s ease-in-out 0s;
  -webkit-transition: all 1s ease-in-out 0s;
}
.table > tbody > tr > td {
  border-color: #5d5d5d;
}
.table > tbody > tr > th {
  border-color: #5d5d5d;
}
.table > tfoot > tr > td {
  border-color: #5d5d5d;
}
.table > tfoot > tr > th {
  border-color: #5d5d5d;
}
.table > thead > tr > td {
  border-color: #5d5d5d;
}
.table > thead > tr > th {
  border-color: #5d5d5d;
}
.align-center {
  text-align: center !important;
}
.align-left {
  text-align: left !important;
}
.align-right {
  text-align: right !important;
}
.title {
  font-size: 24px;
  font-weight: 500;
  opacity: 0.9;
  margin-bottom: 20px;
}
.title_home {
  margin-bottom: 30px;
}
.title_home h2 {
  font-family: Jost;
  font-size: 28px;
  font-weight: 500;
  line-height: 1.61;
}
.title_home p {
  font-size: 16px;
  color: #5d5d5d;
}
.title.title--while {
  color: #ffffff;
  opacity: 1;
}
.title--result span {
  font-family: 'Jost-Book';
  font-size: 16px;
  font-weight: 400;
  color: #5d5d5d;
  vertical-align: 1px;
}
.site-header {
  padding: 20px 0;
  background-color: #ffffff;
  z-index: 1000;
  position: relative;
}
.site-header .container-fluid {
  padding-left: 50px;
  padding-right: 50px;
}
.site-header.float-header {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  background-color: transparent;
}
.main-menu {
  display: inline-block;
  letter-spacing: normal;
}
.main-menu li {
  list-style: none;
  display: inline-block;
  position: relative;
  line-height: 40px;
  margin-right: 25px;
}
.main-menu li a {
  font-weight: 500;
  font-family: 'Jost';
}
.main-menu li i {
  font-size: 12px;
}
.main-menu .sub-menu {
  position: absolute;
  opacity: 0;
  visibility: hidden;
  top: 100%;
  left: 0;
  font-size: 15px;
  min-width: 240px;
  background: #fff;
  padding-top: 10px;
  padding-bottom: 10px;
  margin-top: 50px;
  z-index: 9;
  -webkit-box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.05);
  -moz-box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.05);
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.05);
}
.main-menu .sub-menu li {
  display: block;
  margin-right: 0;
  padding: 8px 15px;
  text-align: left;
  line-height: normal;
}
.main-menu .sub-menu li .sub-menu {
  top: 0;
  left: 100%;
}
.main-menu .sub-menu a {
  display: block;
  position: relative;
}
.main-menu .sub-menu a i {
  position: absolute;
  top: 3px;
  right: 0;
  transform: rotate(-90deg);
}
.main-menu li:hover > .sub-menu {
  opacity: 1;
  visibility: visible;
  margin-top: 0;
  -webkit-transition: all 0.5s ease 0s;
  -moz-transition: all 0.5s ease 0s;
  -o-transition: all 0.5s ease 0s;
  transition: all 0.5s ease 0s;
}
.btn {
  border-radius: 20px;
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  background-color: #1d1d55;
  height: 40px;
  line-height: 40px;
  font-weight: 500;
  padding: 0 25px;
  color: #ffffff;
  outline: 0;
  outline-offset: 0;
}
.btn:hover {
  color: #ffffff;
  opacity: 0.7;
}
.btn:focus {
  box-shadow: none;
  color: #ffffff;
}
.popup-background {
  background-color: rgba(0, 0, 0, 0.8);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 999;
  display: none;
}
.popup {
  position: fixed;
  top: 0;
  left: -280px;
  bottom: 0;
  width: 280px;
  background-color: #ffffff;
  box-shadow: -2px 0 4px 0 rgba(0, 0, 0, 0.2);
  z-index: 1000;
  overflow: auto;
  opacity: 0;
  visibility: hidden;
  transition: all 0.3s;
}
.popup::-webkit-scrollbar {
  width: 0px;
  background-color: #ffeaec;
}
.popup::-webkit-scrollbar-thumb {
  background-color: #1d1d55;
  border-radius: 10px;
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
}
.popup::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #ffeaec;
}
.popup .popup__close {
  position: absolute;
  top: 32px;
  right: 25px;
  cursor: pointer;
  line-height: 0;
  z-index: 9;
}
.popup .popup__close i {
  font-size: 20px;
}
.popup .popup__minicart {
  padding: 0 25px;
}
.popup .popup__button {
  border-bottom: 0;
}
.popup .popup__user {
  padding: 30px 25px;
}
.popup .popup__user a {
  font-family: 'Jost';
  font-size: 15px;
  font-weight: 500;
  margin-right: 25px;
}
.popup .popup__user a:last-child {
  margin-right: 0;
}
.popup .popup__cart {
  padding: 30px 25px;
}
.popup .popup__cart i {
  font-size: 26px;
  vertical-align: -2px;
}
.popup__box {
  padding: 25px;
  border-bottom: 1px solid #eeeeee;
  line-height: normal;
}
.popup__box li {
  list-style: none;
  display: block;
  margin-bottom: 25px;
}
.popup__box li:last-child {
  margin-bottom: 0;
}
.popup__box li a {
  font-family: 'Jost';
  font-size: 15px;
  font-weight: 500;
  margin-right: 25px;
  margin-right: 0;
}
.popup__box li a i {
  vertical-align: 1px;
  margin-left: 3px;
  transition: all 0.3s;
  font-size: 12px;
}
.popup__box li a.open i {
  transition: all 0.3s;
  transform: rotate(180deg);
}
.popup__box li .sub-menu {
  padding-left: 22px;
  margin-top: 25px;
  display: none;
}
.popup--right {
  right: -280px;
  left: auto;
  text-align: left;
}
.popup--right .popup__button {
  padding: 0 25px;
}
.popup--right .popup__button .btn {
  width: 100%;
}
.popup--right .popup__box:last-child {
  border-bottom: 0;
}
.popup.popup--right.open {
  right: 0;
  left: auto;
}
.popup.open {
  opacity: 1;
  visibility: visible;
  left: 0;
}
.product-small {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding: 15px 0;
  border-bottom: 1px solid #eeeeee;
  position: relative;
}
.product-small .product-small__thumb img {
  max-width: 80px;
}
.product-small .product-small__info {
  padding-left: 15px;
  align-self: center;
}
.product-small__info h3 {
  font-size: 15px;
  font-weight: 500;
  margin-bottom: 10px;
}
.product-small__price span {
  font-family: 'Jost';
  font-size: 15px;
  font-weight: 500;
  color: #2d2d2d;
}
.product-small__total {
  padding: 25px 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  justify-content: space-between;
}
.product-small__total b {
  font-size: 18px;
  font-weight: 500;
  font-family: 'Jost';
}
.product-small__total span {
  font-size: 18px;
}
.product-small__remove {
  position: absolute;
  bottom: 15px;
  right: 0;
  line-height: 0;
}
.product-small__remove i {
  font-size: 20px !important;
}
.site__menu {
  line-height: 40px;
  display: inline-block;
  vertical-align: middle;
}
.site__menu > a {
  line-height: 0;
  display: block;
  margin-right: 23px;
}
.site__menu > a i {
  font-size: 20px;
}
a.site__menu__icon {
  display: none;
}
.site__brand {
  line-height: 40px;
  display: inline-block;
  vertical-align: middle;
  margin-right: 23px;
}
.site__search {
  line-height: 40px;
  display: inline-block;
  vertical-align: middle;
  max-width: 270px;
  width: 100%;
  vertical-align: baseline;
  line-height: 40px;
  display: inline-block;
  vertical-align: middle;
  max-width: 270px;
  width: 100%;
  vertical-align: baseline;
}
.site__search .search__close {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 25px;
  line-height: 0;
  z-index: 10;
  display: none;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 25px;
  line-height: 0;
  z-index: 10;
  display: none;
}
.right-header {
  letter-spacing: -0.3em;
}
.right-header > div {
  line-height: 40px;
  display: inline-block;
  vertical-align: middle;
  letter-spacing: normal;
  margin-right: 25px;
}
.right-header > div a {
  font-weight: 500;
  font-family: 'Jost';
}
.right-header > div i {
  font-size: 12px;
}
.right-header > div:last-child {
  margin-right: 0;
}
.right-header > div.right-header__button.btn i {
  font-size: 24px;
  vertical-align: -2px;
}
.right-header .right-header__search {
  margin-right: 25px;
  display: none;
}
.right-header .right-header__search a {
  line-height: 0;
  display: inline-block;
  vertical-align: -3px;
}
.right-header .header-menu li {
  position: relative;
}
.right-header .header-menu li i {
  position: absolute;
  right: 15px;
  top: 13px;
  transform: rotate(-90deg);
}
.right-header .header-menu li:hover .sub-menu.open {
  opacity: 1;
  visibility: visible;
  display: block;
}
.right-header .header-menu li:hover > a {
  color: #1d1d55;
}
.right-header .header-menu .sub-menu {
  top: 0;
  left: 100%;
  padding: 0;
  display: none;
  opacity: 0;
  visibility: hidden;
}
.site__brand__logo img {
  max-width: 200px;
}
.site__search__form {
  line-height: normal;
  border-bottom: 1px solid #d8d8d8;
  position: relative;
  padding-bottom: 3px;
}
.site__search__form .site__search__input {
  border: 0;
  padding: 0;
  font-style: normal;
  color: #8d8d8d;
  margin-left: 30px;
  width: calc(100% - 26px);
  font-family: 'Jost-Book';
}
.site__search__form .site__search__input::placeholder {
  color: #8d8d8d;
  font-weight: 400;
  font-family: 'Jost-Book';
}
.site__search__form .site__search__input:-ms-input-placeholder {
  color: #8d8d8d;
  font-weight: 400;
  font-family: 'Jost-Book';
}
.site__search__form .site__search__input::-ms-input-placeholder {
  color: #8d8d8d;
  font-weight: 400;
  font-family: 'Jost-Book';
}
.site__search__icon {
  line-height: 0;
  display: inline-block;
  position: absolute;
  top: 2px;
  left: 0;
}
.site__search__icon i {
  font-size: 20px;
}
.right-header__destinations {
  position: relative;
}
.right-header__destinations a svg {
  vertical-align: 2px;
}
.right-header__destinations ul {
  position: absolute;
  top: 100%;
  left: 0;
  margin-top: 20px;
  width: 140px;
  padding: 5px 0;
  list-style: none;
  text-align: left;
  -webkit-box-shadow: 0px 3px 8px 0px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0px 3px 8px 0px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 3px 8px 0px rgba(0, 0, 0, 0.1);
  opacity: 0;
  visibility: hidden;
  transition: all 0.3s;
  background-color: #ffffff;
  z-index: 9;
}
.right-header__destinations ul li a {
  display: block;
  padding: 0 15px;
}
.right-header__destinations ul.open {
  margin-top: 0;
  transition: all 0.3s;
  opacity: 1;
  visibility: visible;
}
.right-header__languages {
  position: relative;
}
.right-header__languages a svg {
  vertical-align: 2px;
}
.right-header__languages a img {
  vertical-align: -1px;
}
.right-header__languages ul {
  position: absolute;
  top: 100%;
  left: 0;
  margin-top: 20px;
  width: 140px;
  padding: 5px 0;
  list-style: none;
  text-align: left;
  -webkit-box-shadow: 0px 3px 8px 0px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0px 3px 8px 0px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 3px 8px 0px rgba(0, 0, 0, 0.1);
  opacity: 0;
  visibility: hidden;
  transition: all 0.3s;
  background-color: #ffffff;
  z-index: 9;
}
.right-header__languages ul li a {
  display: block;
  padding: 0 15px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  align-items: center;
}
.right-header__languages ul li img {
  height: 12px;
  margin-right: 7px;
}
.right-header__languages ul li span {
  white-space: nowrap;
  display: inline-block;
  text-overflow: ellipsis;
  width: 85px;
  overflow: hidden;
}
.right-header__languages ul.open {
  margin-top: 0;
  transition: all 0.3s;
  opacity: 1;
  visibility: visible;
}
.right-header__minicart svg {
  vertical-align: -3px;
}
.right-header__minicart svg:hover > * {
  fill: #1d1d55;
}
.right-header__minicart .right-header__number {
  color: #1d1d55;
}
.flex {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  justify-content: space-between;
}
.banner-left {
  background-color: #ffeaec;
  width: 45%;
  position: relative;
}
.banner-left:after {
  content: '';
  background-image: 'url(/images/bg/bg-right.png)';
  background-repeat: no-repeat;
  background-size: cover;
  width: 50px;
  height: 100%;
  position: absolute;
  top: 0;
  bottom: 0;
  right: -50px;
  z-index: 9;
}
.banner.slick-sliders {
  width: 55%;
}
.banner.slick-sliders .banner-sliders {
  position: relative;
  margin-bottom: 0;
}
.banner.slick-sliders .slick-dots {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 50px;
  width: auto;
  bottom: inherit;
  left: auto;
}
.banner.slick-sliders .slick-dots li {
  margin-bottom: 7px;
  margin-right: 0;
  display: block;
}
.banner.slick-sliders .slick-dots li button:before {
  color: #ffffff;
  font-size: 12px;
  opacity: 0.5;
}
.banner.slick-sliders .slick-dots li.slick-active button:before {
  opacity: 1;
}
.banner.slick-sliders .slick-dots li:last-child {
  margin-bottom: 0;
}
.banner.slick-sliders img {
  height: 650px;
  width: 100%;
  object-fit: cover;
}
.banner.slick-sliders .slick-slide > div {
  height: 650px;
}
.right-header__button {
  background-color: #1d1d55;
  border-radius: 20px;
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  padding: 0 25px;
  color: #ffffff;
}
.right-header__button svg {
  vertical-align: -1px;
  margin-right: 2px;
}
.right-header__button a {
  color: #ffffff;
  font-weight: 500;
  font-family: 'Jost-Book';
  display: block;
}
.site-banner {
  height: 500px;
  background-color: #ffeaec;
  background-repeat: no-repeat;
  background-position: right 30px bottom;
}
.site-banner .container {
  position: relative;
  height: 100%;
  z-index: 7;
}
.site-banner .site-banner__content {
  position: absolute;
  top: 50%;
  /* transform: translateY(-50%); */
  left: 15px;
  right: 15px;
}
.site-banner.layout-02 {
  background-position: bottom right;
  background-repeat: no-repeat;
  background-size: 50vw auto;
  height: 100vh;
  background-color: #f5f8fd;
}
.site-banner.layout-02 .site-banner__title {
  color: #2d2d2d;
  font-size: 72px;
  font-weight: 500;
  display: inline-block;
  position: relative;
  margin-bottom: 10px;
  margin-top: 100px;
}
.site-banner.layout-02 .site-banner__title:after {
  content: 'url(/images/workspace/banner-line.png)';
  background-position: 60% 100%;
  background-repeat: no-repeat;
  position: absolute;
  right: 36px;
  bottom: -20px;
}
.site-banner.layout-02 .site-banner__content > p {
  font-size: 24px;
  font-weight: 400;
  margin-bottom: 30px;
}
.site-banner.layout-02 .site-banner__content > p strong {
  color: #2d2d2d;
}
.site-banner.layout-02 .site-banner__search {
  max-width: 470px;
}
.site-banner__title {
  font-size: 52px;
  font-family: 'Jost';
  font-weight: 500;
  color: #2d2d2d;
  margin-bottom: 32px;
}
.site-banner__search {
  position: relative;
  max-width: 600px;
  width: 100%;
  margin-bottom: 20px;
}
.site-banner__search .site-banner__search__input {
  border: 0;
  font-style: inherit;
  padding: 0;
  line-height: 15px;
  width: 100%;
  border-radius: 30px;
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
  background-color: #ffffff;
  padding: 0 15px 0 54px;
}
.site-banner__search__icon {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 20px;
  line-height: 0;
}
.site-banner__search__icon i {
  font-size: 22px;
}
.minicart__open i {
  font-size: 27px !important;
  vertical-align: -3px;
}
.site-banner__search__input::placeholder {
  color: #8d8d8d;
  font-weight: 400;
  font-family: 'Jost-Book';
  font-size: 16px;
}
.site-banner__search__input:-ms-input-placeholder {
  color: #8d8d8d;
  font-weight: 400;
  font-family: 'Jost-Book';
  font-size: 16px;
}
.site-banner__search__input::-ms-input-placeholder {
  color: #8d8d8d;
  font-weight: 400;
  font-family: 'Jost-Book';
  font-size: 16px;
}
.site-banner__meta span {
  padding-left: 20px;
  opacity: 0.8;
  font-family: 'Jost-Book';
  color: #2d2d2d;
  margin-right: 1px;
}
.site-banner__meta a {
  opacity: 1;
  font-family: 'Jost';
  font-weight: 500;
  color: #2d2d2d;
  position: relative;
  margin-right: 8px;
  transition: inherit;
  -moz-transition: inherit;
  -o-transition: inherit;
  -webkit-transition: inherit;
  -ms-transition: inherit;
}
.site-banner__meta a:hover {
  opacity: 0.7;
}
.site-banner__meta a:last-child {
  margin-right: 0;
}
.site-banner__meta a:after {
  content: '';
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  height: 1px;
  background-color: #2d2d2d;
}
.hover__box__thumb {
  overflow: hidden;
  display: block;
  position: relative;
}
.hover__box img {
  -webkit-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
  transform: scale(1);
}
.hover__box:hover img {
  transform: scale(1.1);
}
.banner-apps {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 123px 0 173px;
}
.banner-apps__content {
  padding-left: 100px;
}
.banner-apps__content .banner-apps__title {
  font-size: 48px;
  font-weight: 500;
  color: #1a1a1a;
  line-height: 66px;
  margin-bottom: 5px;
}
.banner-apps__content .banner-apps__desc {
  font-size: 24px;
  color: #1a1a1a;
  line-height: 33px;
  font-family: 'Jost-Book';
  margin-bottom: 40px;
}
.banner-apps__content .banner-apps__download > a {
  max-width: 200px;
  display: inline-block;
  margin-right: 20px;
}
.banner-apps__content .banner-apps__download > a:last-child {
  margin-right: 0;
}
.title--more a {
  opacity: 0.9;
  font-size: 15px;
  font-weight: 500;
  color: #1d1d55;
  float: right;
  position: relative;
  margin-top: 10px;
}
.title--more a:after {
  content: '';
  display: block;
  position: absolute;
  left: 0;
  bottom: 0;
  right: 0;
  height: 1px;
  background-color: #1d1d55;
  width: 0;
  -webkit-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
}
.title--more a:hover:after {
  width: 100%;
}
.title--more a i {
  vertical-align: 0px;
}
.page-title {
  position: relative;
  height: 400px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.page-title__content {
  text-align: center;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 15px;
  right: 15px;
}
.page-title__content > * {
  color: #ffffff;
}
.page-title__content h4 {
  font-size: 16px;
  font-weight: 500;
  text-transform: uppercase;
  margin-bottom: 5px;
}
.page-title__content h1 {
  font-size: 64px;
  font-weight: 500;
}
.page-title__content .page-title__slogan {
  font-size: 18px;
  font-style: italic;
}
.slick-slide > div {
  line-height: 0;
}
.slick-slide > div > * {
  line-height: normal;
}
.banner-dark {
  opacity: 0.9;
  background-color: #000000;
  padding: 40px 0 30px;
}
.slick-sliders {
  position: relative;
}
.slick-sliders img {
  height: 400px;
  object-fit: cover;
  width: 100%;
}
.slick-slider img {
  height: 400px;
  object-fit: cover;
}
.slick-slider .slick-slide > div {
  line-height: 0;
}
.place-slider__grid {
  margin-bottom: 0;
  position: relative;
}
.place-slider__grid .slick-slide > div {
  line-height: 0;
}
.place-slider__grid .place-slider__item img {
  padding: 0 1px;
}
.place-item__photo {
  position: absolute;
  right: 50px;
  bottom: 30px;
}
.place-item__photo svg {
  display: inline-block;
  vertical-align: middle;
}
.place-item__photo span {
  display: inline-block;
  vertical-align: middle;
}
.place-item__photo a span {
  color: #ffffff;
  font-size: 16px;
}
.place-item__icon {
  position: absolute;
  top: 30px;
  right: 50px;
}
.place-item__icon > a {
  opacity: 0.7;
  transition: all 0.3s;
  margin-right: 25px;
}
.place-item__icon > a i {
  font-size: 28px;
  color: #ffffff;
}
.place-item__icon > a:last-child {
  margin-right: 0;
}
.place-item__icon a:hover {
  opacity: 1;
}
.slick-nav > div {
  position: absolute;
  left: -50px;
  top: 35%;
  transform: translateY(-50%);
  height: 50px;
  width: 50px;
  line-height: 50px;
  text-align: center;
  border-radius: 50%;
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  background-color: #ffffff;
  cursor: pointer;
  opacity: 0.7;
  transition: all 0.3s;
}
.slick-nav > div:hover {
  opacity: 1;
}
.slick-nav .slick-nav__next {
  right: -50px;
  left: auto;
}
.slick-nav i {
  font-size: 24px;
  vertical-align: -2px;
}
.slick-dots {
  position: absolute;
  left: 0;
  right: 0;
  bottom: -40px;
  height: 10px;
}
.slick-dots li {
  width: auto;
  height: auto;
  margin: 0 15px 0 0;
}
.slick-dots li:last-child {
  margin-right: 0;
}
.slick-dots li button {
  width: 10px;
  height: 10px;
  font-size: inherit;
  line-height: normal;
}
.slick-dots li button:before {
  content: '';
  background-color: #1d1d55;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  opacity: 0.5;
}
.slick-dots li.slick-active button:before {
  opacity: 1;
}
.place-banner img {
  height: 500px;
  object-fit: cover;
}
.breadcrumbs {
  margin-bottom: 5px;
}
.breadcrumbs li {
  list-style: none;
  display: inline-block;
  margin-right: 8px;
}
.breadcrumbs li:last-child {
  margin-right: 0;
}
.breadcrumbs li a {
  color: #8d8d8d;
  font-size: 14px;
  text-transform: uppercase;
  position: relative;
  line-height: 19px;
}
.breadcrumbs li a:after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 1px;
  background-color: #1d1d55;
}
.breadcrumbs li a:hover {
  color: #1d1d55;
}
.place__left h1 {
  font-size: 36px;
  font-family: 'Jost-Book';
  margin-bottom: 6px;
  font-weight: 400;
}
.place__left--top {
  margin-top: -130px;
  background-color: #ffffff;
  padding: 30px;
}
.place__meta {
  margin-bottom: 20px;
}
.place__meta > div {
  display: inline-block;
  margin-right: 18px;
}
.place__meta > div:last-child {
  margin-right: 0;
}
.reviews .reviews__number {
  color: #1d1d55;
}
.reviews .reviews__number svg > * {
  fill: #1d1d55;
}
.place__category a:after {
  content: ',';
}
.place__category a:last-child:after {
  content: '';
}
.place__desc {
  line-height: 1.44;
  font-size: 16px;
  margin-bottom: 30px;
}
.btn {
  border-radius: 20px;
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  background-color: #1d1d55;
  height: 40px;
  line-height: 40px;
  font-weight: 500;
  padding: 0 25px;
  color: #ffffff;
  outline: 0;
  outline-offset: 0;
}
.btn:hover {
  color: #ffffff;
}
.btn:focus {
  box-shadow: none;
  color: #ffffff;
}
.place__box {
  border-bottom: 1px solid #eee;
  padding: 20px 0;
}
.place__box h3 {
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 20px;
}
.place__box p {
  line-height: 1.44;
  font-size: 16px;
}
.place__box:last-child {
  padding-bottom: 0;
  border-bottom: 0;
  margin-bottom: 60px;
}
.place__box .place__amenities i {
  font-size: 26px;
  vertical-align: -6px;
}
.place__box.place__box--npd {
  padding-top: 0 !important;
}
.place__box.place__box--30 {
  padding: 30px 0;
}
.place__amenities svg {
  display: inline-block;
  vertical-align: middle;
}
.place__amenities span {
  display: inline-block;
  vertical-align: middle;
  line-height: normal;
  line-height: 1.44;
  font-size: 16px;
  vertical-align: -3px;
  margin-left: 15px;
}
.place__title--additional a {
  float: right;
}
.place__title--additional a span {
  font-weight: 500;
  color: #1d1d55;
  font-size: 15px;
}
.place__title--additional a i {
  font-size: 24px;
  vertical-align: -3px;
}
.maps #map {
  height: 300px;
}
.place__contact li {
  list-style: none;
  position: relative;
  padding-left: 25px;
  margin-bottom: 15px;
}
.place__contact li:last-child {
  margin-bottom: 0;
}
.place__contact li i {
  position: absolute;
  top: 2px;
  left: 0;
  width: 14px;
  text-align: center;
  font-size: 20px;
}
.place__contact li a {
  display: inline-block;
  vertical-align: middle;
  color: #5d5d5d;
  font-size: 16px;
  line-height: 1.44;
}
.place__contact li a:hover {
  color: #1d1d55;
}
.place__contact li span {
  display: inline-block;
  vertical-align: middle;
  color: #5d5d5d;
  font-size: 16px;
  line-height: 1.44;
}
.place__title--reviews .place__reviews__number {
  font-size: 18px;
  color: #1d1d55;
  margin-left: 13px;
}
.place__title--reviews .place__reviews__number span {
  color: #8d8d8d;
  line-height: 1.53;
  font-family: 'Jost-Book';
}
.place__title--reviews svg {
  margin-right: 10px;
}
.place__title--reviews svg > * {
  fill: #1d1d55;
}
.place__comments {
  margin-bottom: 20px;
  list-style: none;
}
.place__comments li {
  padding: 20px 0;
  border-top: 1px solid #eee;
}
.place__comments li ul {
  padding-left: 60px;
  padding-top: 20px;
}
.place__comments li ul li {
  list-style: none;
}
.place__comments li ul li:first-child {
  padding-top: 20px;
  border-top: 1px solid #eee;
}
.place__comments li ul li .place__comments__content p {
  margin-bottom: 0;
}
.place__comments li ul li .place__comments__content img {
  margin-bottom: 0;
}
.place__comments li:first-child {
  padding-top: 0;
  border-top: 0;
}
.place__comments li:last-child {
  padding-bottom: 0;
}
.place__comments li .place__author__avatar {
  margin-right: 10px;
}
.place__comments li .place__author__avatar img {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
}
.place__comments li .place__author > div {
  display: inline-block;
  vertical-align: middle;
}
.place__comments > li:last-child {
  padding-bottom: 20px;
  border-bottom: 1px solid #eee;
}
.place__comments .place__author {
  margin-bottom: 20px;
}
.place__author__info .place__author__star {
  display: inline-block;
  position: relative;
}
.place__author__info .place__author__star i {
  color: #5d5d5d;
}
.place__author__info .place__author__star span {
  position: absolute;
  left: 0;
  top: 0;
  white-space: nowrap;
  display: inline-block;
  overflow: hidden;
}
.place__author__info .place__author__star span i {
  color: #1d1d55;
}
.place__author__info a {
  font-size: 15px;
  font-weight: 500;
  margin-right: 10px;
}
.place__author__info span.time {
  font-family: 'Jost-Book';
  font-size: 13px;
  color: #9d9d9d;
  font-style: italic;
  display: block;
}
.place__comments__content p {
  font-size: 16px;
  line-height: 1.44;
  margin-bottom: 20px;
}
.place__comments__content .row {
  margin-left: -5px;
  margin-right: -5px;
}
.place__comments__content .row > div {
  padding: 0 5px;
}
.comment-slider {
  margin-right: -5px;
  margin-left: -5px;
  margin-bottom: 25px;
}
.comment-slider .comment-slider-item {
  padding: 0 5px;
}
.comment-slider .slick-slider img {
  height: auto;
}
a.place__comments__reply {
  color: #2d2d2d;
}
a.place__comments__reply i {
  display: inline-block;
  vertical-align: middle;
  margin-right: 8px;
  font-size: 20px;
}
.place__box--reviews .place__login a {
  font-family: 'Jost';
  font-weight: 500;
  color: #1d1d55;
}
.similar-places {
  padding: 40px 0 60px;
  background-color: #ffeaec;
}
.places-item {
  position: relative;
  overflow: hidden;
  margin-bottom: 30px;
  border-radius: 15px;
  -moz-border-radius: 15px;
  -webkit-border-radius: 15px;
  -ms-border-radius: 15px;
  -o-border-radius: 15px;
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
}
.places-item .places-item__thumb a {
  display: block;
  position: relative;
}
.places-item .places-item__thumb a:after {
  content: '';
  position: absolute;
  left: 0;
  bottom: 0;
  right: 0;
  height: 120px;
  background-image: linear-gradient(
    to bottom,
    rgba(255, 255, 255, 0),
    rgba(0, 0, 0, 0.6)
  );
}
.places-item .places-item__thumb img {
  width: 270px;
  height: 250px;
  object-fit: cover;
}
.places-item .places-item__info {
  position: absolute;
  left: 0;
  bottom: 0;
  right: 0;
  padding: 0 20px 20px;
}
.places-item .places-item__info a {
  color: #ffffff;
}
.places-item h3 {
  font-family: 'Jost-Book';
  font-size: 24px;
  font-weight: normal;
  margin-bottom: 3px;
}
.places-item .place-item__addwishlist {
  position: absolute;
  top: 20px;
  right: 20px;
}
.places-item .place-item__addwishlist:hover {
  opacity: 0.7;
}
.places-item .place-item__addwishlist i {
  border-radius: 50%;
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  background: #ffffff;
  padding: 4px;
  font-size: 24px;
  color: #2d2d2d;
}
.places-item .place-item__addwishlist.active i {
  color: #1d1d55;
}
.places-item__category a {
  font-family: 'Jost-Book';
  font-size: 12px;
  text-transform: uppercase;
}
.places-item__category a:after {
  content: ',';
}
.places-item__category a:last-child:after {
  content: '';
}
.places-item__category a:hover {
  color: #1d1d55;
}
.places-item__info h3 a:hover {
  color: #1d1d55;
}
.places-item__meta:after {
  content: '';
  display: block;
  clear: both;
}
.places-item__meta .places-item__reviews {
  float: left;
}
.places-item__meta .places-item__currency {
  float: right;
  color: #ffffff;
}
.places-item__meta .places-item__count {
  color: #ffffff;
}
.places-item__reviews .places-item__number {
  color: #1d1d55;
}
.places-item__reviews .places-item__number svg > * {
  fill: #1d1d55;
  stroke: #1d1d55;
}
.page-title--small {
  height: 150px;
  background-color: #1d1d55;
  margin-bottom: 60px;
  background-repeat: no-repeat;
  background-position: center right;
  background-size: contain;
}
.page-title--small .container {
  position: relative;
  height: 100%;
}
.page-title--small .page-title__content {
  text-align: left;
}
.page-title--small .page-title__content h1 {
  color: #ffffff;
  font-size: 40px;
  margin-bottom: 5px;
}
.page-title--small .page-title__content p {
  color: #ffffff;
  font-size: 16px;
  font-style: normal;
}
.site__order {
  text-align: right;
}
.site__order > div {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  margin-right: 23px;
}
.site__order > div:last-child {
  margin-right: 0;
}
.site__order > div > a {
  font-weight: 500;
  font-family: 'Jost';
}
.site__order > div > a i {
  font-size: 12px;
}
.site__order ul.open {
  opacity: 1;
  visibility: visible;
  margin-top: 0;
}
.site__order ul {
  position: absolute;
  top: 100%;
  right: 0;
  margin-top: 20px;
  width: 140px;
  padding: 5px 0;
  list-style: none;
  text-align: left;
  -webkit-box-shadow: 0px 3px 8px 0px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0px 3px 8px 0px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 3px 8px 0px rgba(0, 0, 0, 0.1);
  opacity: 0;
  visibility: hidden;
  transition: all 0.3s;
  background-color: #ffffff;
  z-index: 9;
}
.site__order ul li {
  list-style: none;
}
.site__order ul li a {
  display: block;
  padding: 5px 15px;
}
.site__filter a.active i {
  -webkit-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  -o-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  transform: rotate(180deg);
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
}
.custom-scrollbar::-webkit-scrollbar {
  width: 6px;
  background-color: #ffeaec;
  border-radius: 5px;
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
}
.custom-scrollbar::-webkit-scrollbar-thumb {
  background-color: #a7a7a7;
  border-radius: 5px;
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
}
.custom-scrollbar::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #ffeaec;
  border-radius: 5px;
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
}
.pagination {
  text-align: center;
  margin-bottom: 60px;
  display: block;
  margin-left: -5px;
  margin-right: -5px;
}
.pagination__numbers a {
  color: #5d5d5d;
  width: 40px;
  height: 40px;
  display: inline-block;
  line-height: 40px;
  vertical-align: middle;
  margin: 0 5px;
  text-align: center;
  border-radius: 50%;
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
}
.pagination__numbers a:hover {
  background-color: #eeeeee;
}
.pagination__numbers span {
  color: #5d5d5d;
  width: 40px;
  height: 40px;
  display: inline-block;
  line-height: 40px;
  vertical-align: middle;
  margin: 0 5px;
  text-align: center;
  border-radius: 50%;
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  color: #ffffff;
  background-color: #1d1d55;
}
.other-products {
  padding-top: 40px;
  padding-bottom: 20px;
}
.page-title--blog {
  margin-bottom: 60px;
}
.entry-meta {
  margin-bottom: 30px;
}
.entry-meta li {
  font-style: italic;
  font-size: 15px;
  color: #8d8d8d;
  display: inline-block;
  list-style: none;
  margin-right: 24px;
  position: relative;
}
.entry-meta li:last-child {
  margin-right: 0;
}
.entry-meta li:after {
  content: '';
  height: 4px;
  width: 4px;
  background-color: #d8d8d8;
  position: absolute;
  top: 8px;
  left: -15px;
  border-radius: 50%;
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
}
.entry-meta li:first-child:after {
  content: '';
  display: none;
}
.entry-meta li a {
  font-weight: 500;
  color: #8d8d8d;
  font-family: 'Jost';
}
.entry-meta li a:hover {
  color: #1d1d55;
}
.entry-desc {
  font-size: 16px;
  line-height: 1.44;
  margin-bottom: 60px;
}
.entry-desc p {
  margin-bottom: 30px;
}
.entry-desc img {
  margin-bottom: 30px;
  height: 480px;
  object-fit: cover;
}
.entry-desc blockquote {
  padding: 11px 20px;
  border-left: 2px solid #1d1d55;
  margin-bottom: 30px;
}
.entry-desc blockquote p {
  margin-bottom: 0;
  font-size: 20px;
  font-style: italic;
}
.entry-desc .entry-image {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}
.entry-desc .entry-image img {
  padding: 0 15px;
  margin-bottom: 0;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 50%;
  flex: 0 0 50%;
  max-width: 50%;
  height: 360px;
  object-fit: cover;
}
.entry-author {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  align-items: center;
  padding-top: 30px;
  border-top: 1px solid #eeeeee;
  margin-bottom: 30px;
}
.entry-author > img {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  object-fit: cover;
}
.entry-author .author-info {
  margin-left: 20px;
}
.entry-author .author-desc {
  font-size: 16px;
  line-height: 1.44;
}
.author-title > a {
  font-family: 'Jost';
  font-size: 14px;
  font-weight: 500;
}
.author-title > a:hover {
  color: #1d1d55;
}
.author-social {
  display: inline-block;
  vertical-align: middle;
  margin-left: 18px;
}
.author-social li {
  list-style: none;
  display: inline-block;
  margin-right: 18px;
  font-size: 20px;
}
.author-social li:last-child {
  margin-right: 0;
}
.author-social li svg:hover > * {
  fill: #1d1d55;
}
.entry-comment {
  padding-top: 30px;
  border-top: 1px solid #eeeeee;
}
.popup-form {
  top: 0;
  left: 15px;
  right: 15px;
  bottom: auto;
  margin: 0 auto 80px !important;
  transition: all 0.3s;
  text-align: left;
  padding: 50px 30px 30px;
  max-width: 400px;
  width: 100%;
}
.popup-form .popup__close {
  top: 30px;
  right: 30px;
}
.popup-form .popup-logo {
  display: inline-block;
  margin-bottom: 50px;
}
.popup-form .popup-logo img {
  max-width: 96px;
}
.popup-form .choose-form {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 30px;
}
.popup-form .choose-form li {
  list-style: none;
  display: inline-block;
  margin-right: 20px;
}
.popup-form .choose-form li a {
  font-size: 20px;
  font-weight: 500;
  font-family: 'Jost';
  line-height: normal;
  text-align: center;
  border-bottom: 2px solid transparent;
  line-height: 19px;
  display: block;
  padding-bottom: 10px;
  color: #8d8d8d;
}
.popup-form .choose-form li:last-child {
  margin-right: 0;
}
.popup-form .choose-form li.active a {
  color: #2d2d2d;
  border-bottom-color: #2d2d2d;
}
.popup-form .choose-more {
  line-height: normal;
  font-size: 16px;
  margin-bottom: 30px;
}
.popup-form .choose-more .fb {
  color: #4267b2;
}
.popup-form .choose-more .gg {
  color: #ea4335;
}
.popup-form .choose-or {
  position: relative;
  line-height: normal;
  margin-bottom: 20px;
}
.popup-form .choose-or span {
  position: relative;
  z-index: 1;
  background-color: #ffffff;
  padding: 0 23px 0 0;
  vertical-align: -1px;
}
.popup-form .choose-or:before {
  content: '';
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  right: 0;
  height: 1px;
  background-color: #eeeeee;
}
.popup-form .field-inline {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-left: -15px;
  margin-right: -15px;
}
.popup-form .field-inline .field-input {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 50%;
  flex: 0 0 50%;
  max-width: 50%;
  padding-left: 15px;
  padding-right: 15px;
}
.popup-form input {
  width: 100%;
  height: 40px;
  line-height: 40px;
  border-radius: 0;
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  border: 0;
  padding: 0;
  border-bottom: 2px solid #2d2d2d;
  margin-bottom: 20px;
  font-style: normal;
}
.popup-form input[type='submit'] {
  border-radius: 20px;
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  background-color: #1d1d55;
  color: #ffffff;
  margin-bottom: 0;
  cursor: pointer;
  border: 0;
}
.popup-form input[type='checkbox'] {
  width: auto;
  line-height: normal;
  height: auto;
  position: absolute;
  opacity: 0;
  visibility: hidden;
}
.popup-form .forgot_pass {
  color: #1d1d55;
  display: block;
  margin-bottom: 20px;
  line-height: normal;
}
.form-sign .field-check label {
  -ms-flex: 0 0 100%;
  flex: 0 0 100%;
  max-width: 100%;
}
.popup-form.open {
  top: 80px;
  opacity: 1;
  visibility: visible;
  left: 15px;
}
.field-check {
  line-height: normal;
  margin-bottom: 20px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-left: -15px;
  margin-right: -15px;
}
.field-check label {
  position: relative;
  display: inline-block;
  line-height: normal;
  padding-left: 26px;
  cursor: pointer;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 25%;
  flex: 0 0 25%;
  max-width: 25%;
  padding: 0 15px;
}
.field-check label a {
  color: #00d3d4;
}
.field-check label:before {
  content: '';
  height: 16px;
  width: 16px;
  border-radius: 3px;
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  border: solid 1px #dddddd;
  display: block;
  position: absolute;
  left: 0;
  top: 2px;
  border-width: 2px;
  position: static;
  display: inline-block;
  vertical-align: -3px;
  margin-right: 8px;
  border-color: #8d8d8d;
}
.field-check label span {
  position: absolute;
  border-radius: 3px;
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  text-align: center;
  height: 16px;
  width: 16px;
  top: 3px;
  background-color: #2d2d2d;
  left: 0;
  line-height: 14px;
  opacity: 0;
  visibility: hidden;
  transition: all 0.3s;
  top: 6px;
  left: 19px;
  transition: inherit;
}
.field-check label input:checked ~ span {
  opacity: 1;
  visibility: visible;
  text-align: center;
  height: 16px;
  width: 16px;
  top: 3px;
  background-color: #2d2d2d;
  left: 15px;
  line-height: 14px;
  border-radius: 3px;
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
}
.field-check label input:checked ~ span i {
  font-size: 12px;
  color: #ffffff;
}
.field-check input {
  position: absolute;
  opacity: 0;
  visibility: hidden;
}
.place-flex {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.place-flex .place__currency {
  margin-right: 110px;
}
.place-flex svg {
  display: inline-block;
  vertical-align: middle;
  margin-right: 8px;
}
.place-flex p {
  display: inline-block;
  font-size: 15px;
  vertical-align: middle;
}
.place-flex p span {
  color: #8d8d8d;
}
.place-flex p a {
  color: #8d8d8d;
}
.listing-main {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  justify-content: flex-end;
  border-top: 1px solid #eeeeee;
}
.listing-main .listing-nav {
  background-color: #ffffff;
  position: fixed;
  left: 0;
  bottom: 0;
  top: 80px;
  width: 30%;
  z-index: 9;
  border-top: 1px solid #eee;
  transition: all 0.3s;
}
.listing-main .listing-nav.on_scroll {
  top: 0;
}
.listing-main .listing-nav.is_footer {
  position: relative;
}
.listing-main .listing-content {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 70%;
  flex: 0 0 70%;
  max-width: 70%;
}
.listing-menu {
  position: absolute;
  top: 120px;
  right: 0;
  max-width: 180px;
  width: 100%;
}
.listing-menu li {
  list-style: none;
  margin-bottom: 20px;
}
.listing-menu li span.icon {
  display: none;
}
.listing-menu li a {
  font-size: 16px;
  color: #8d8d8d;
  display: block;
  line-height: 26px;
  height: 26px;
  border-right: 4px solid transparent;
  transition: all 0.3s;
  font-family: 'Jost';
}
.listing-menu li:hover a {
  color: #2d2d2d;
  border-right: 4px solid #1d1d55;
}
.listing-menu li.active a {
  color: #2d2d2d;
  font-weight: 500;
  border-right: 4px solid #1d1d55;
}
.listing-content {
  border-left: 1px solid #eeeeee;
  background-color: #ffeaec;
  padding: 60px;
}
.listing-content > h2 {
  font-size: 28px;
  font-weight: 500;
  margin-bottom: 30px;
}
.chosen-container {
  width: 100% !important;
}
.field-select {
  position: relative;
  position: relative;
  margin-bottom: 30px;
}
.field-select select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-color: transparent;
  z-index: 2;
  position: relative;
}
.field-select > i {
  position: absolute;
  bottom: 13px;
  right: 0;
  z-index: 1;
  font-size: 12px;
}
.field-select #select-button {
  position: relative;
  border: 1px solid #2d2d2d;
  height: 70px;
  line-height: 68px;
  padding: 0 14px;
  background-color: #ffffff;
  border-radius: 3px;
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  cursor: pointer;
}
.field-select #options-view-button {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  margin: 0;
  opacity: 0;
  cursor: pointer;
  z-index: 3;
}
.field-select #options-view-button:checked + #select-button #chevrons img {
  color: #2d3667;
}
.field-select #options-view-button:checked ~ #options {
  border: 1px solid #e2eded;
  border-color: #eaf1f1 #e4eded #dbe7e7 #e4eded;
}
.field-select #options-view-button:checked ~ #options .option img {
  display: block;
}
.field-select #options-view-button:checked ~ #options .label {
  display: block;
  padding: 12px 14px;
}
.field-select #options-view-button:checked ~ #options #option-bg {
  display: block;
}
.field-select
  #options-view-button:not(:checked)
  ~ #options
  .option
  input[type='radio']:checked
  ~ .opt-val {
  top: -45px;
  left: 1px;
}
.field-select
  #options-view-button:not(:checked)
  ~ #options
  .option
  input[type='radio']:checked
  ~ .opt-val
  img {
  display: block;
}
.field-select #selected-value {
  font-size: 16px;
  height: 100%;
  margin-right: 26px;
}
.field-select .option {
  color: #2d3667;
  font-size: 16px;
  position: relative;
  line-height: 1;
  transition: 0.3s ease all;
  z-index: 2;
}
.field-select .option img {
  height: 20px;
  height: 20px;
  position: absolute;
  left: 14px;
  padding: 0;
  display: none;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}
.field-select .option input[type='radio']:checked ~ .opt-val {
  opacity: 1;
  transform: scale(1);
}
.field-select .option input[type='radio']:checked ~ img {
  opacity: 1;
  animation: unset;
  color: #ffffff;
}
.field-select .option input[type='radio']:checked ~ .label {
  color: #ffffff;
}
.field-select .option input[type='radio']:checked ~ .label:before {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -1;
}
.field-select .option:hover .label {
  color: #ffffff;
}
.field-select .label {
  color: #2d3667;
  font-size: 16px;
  display: none;
  padding: 0;
  margin-left: 47px;
  transition: 0.3s ease all;
}
.field-select #chevrons {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  font-size: 24px;
  padding: 0 14px;
}
.field-select #chevrons img {
  display: block;
  height: 50%;
  color: #d1dede;
  font-size: 12px;
  text-align: right;
}
.field-select .options {
  position: absolute;
  left: 0;
  width: 250px;
}
.field-select #options {
  position: absolute;
  top: 70px;
  right: 0;
  left: 0;
  width: 100%;
  margin: 0 auto;
  background-color: #ffffff;
  border-radius: 4px;
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  z-index: 99;
}
.field-select .s-c {
  position: absolute;
  left: 0;
  width: 100%;
  height: 100%;
}
.field-select input[type='radio'] {
  position: absolute;
  right: 0;
  left: 0;
  width: 100%;
  height: 100%;
  margin: 0;
  opacity: 0;
  cursor: pointer;
}
.field-select .opt-val {
  position: absolute;
  left: 0;
  opacity: 0;
  width: 200px;
  background-color: #ffffff;
  transform: scale(0);
  font-size: 16px;
  color: #2d2d2d;
  padding-left: 61px;
}
.field-select .option.o-paypal input[type='radio']:checked ~ .label:before {
  background-color: #012169;
  border-radius: 4px 4px 0 0;
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
}
.field-select .option.o-paypal input[type='radio']:checked ~ .opt-val {
  top: -46px;
}
.field-select .option.o-paypal:hover ~ #option-bg {
  top: 0;
  background-color: #012169;
  border-radius: 4px 4px 0 0;
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
}
.field-select .option.o-stripe input[type='radio']:checked ~ .label:before {
  background-color: #00afe1;
}
.field-select .option.o-stripe input[type='radio']:checked ~ .opt-val {
  top: -86px;
}
.field-select .option.o-stripe:hover ~ #option-bg {
  top: 40px;
  background-color: #00afe1;
}
.field-select .option.o-skrill input[type='radio']:checked ~ .label:before {
  background-color: #862165;
}
.field-select .option.o-skrill input[type='radio']:checked ~ .opt-val {
  top: -126px;
}
.field-select .option.o-skrill:hover ~ #option-bg {
  top: 80px;
  background-color: #862165;
}
.field-select .option.o-master input[type='radio']:checked ~ .label:before {
  background-color: #000066;
}
.field-select .option.o-master input[type='radio']:checked ~ .opt-val {
  top: -166px;
}
.field-select .option.o-master:hover ~ #option-bg {
  top: 120px;
  background-color: #000066;
}
.field-select #option-bg {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  height: 40px;
  transition: 0.3s ease all;
  z-index: 1;
  display: none;
}
.upload-form {
  max-width: 660px;
}
#location-map {
  height: 231px;
}
#hightlight .field-check {
  margin-bottom: 0;
}
.ql-toolbar.ql-snow {
  background-color: #eeeeee;
  border: 0;
}
.ql-container.ql-snow {
  border-top: 0;
  border-left: 0;
  border-right: 0;
  border-bottom: 1px solid #eeeeee;
  height: 120px;
}
.chosen-container-multi .chosen-choices li.search-field input[type='text'] {
  font-family: 'Jost-Book';
  font-size: 15px !important;
  color: #8d8d8d !important;
}
.chosen-container-multi .chosen-choices li.search-choice {
  font-family: 'Jost';
  font-size: 15px;
  border: 0;
  padding: 0 15px 0 0;
  margin: 0 10px 0 0;
  background-color: transparent;
  color: #8d8d8d;
  background-image: initial;
  line-height: inherit;
  box-shadow: inherit;
}
.chosen-container-multi .chosen-choices li.search-choice .search-choice-close {
  right: 0;
  top: 10px;
}
.field-maps .field-inline label {
  padding: 0 15px;
}
.field-maps .field-inline .field-button {
  padding: 0 15px;
}
.field-maps .field-button a {
  font-family: 'Jost';
  font-size: 15px;
  font-weight: 500;
  color: #2d2d2d;
}
.field-maps .field-button svg {
  vertical-align: -1px;
  margin-right: 3px;
}
.field-maps .field-map {
  position: relative;
}
.field-maps .field-map .address-input {
  height: 40px;
  line-height: 38px;
  border-radius: 3px;
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  border: solid 1px #eeeeee;
  background-color: #ffffff;
  max-width: 307px;
  position: absolute;
  left: 20px !important;
  top: 20px !important;
  z-index: 3;
  padding: 0 18px;
  font-style: italic;
}
.field-maps .field-map #location-map {
  position: relative;
  z-index: 1;
}
.search-choice-close:before {
  content: '';
  height: 10px;
  width: 10px;
  background-image: 'url(/images/icon-close-12.html)';
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  display: block;
}
#social .field-3col .remove-social {
  line-height: normal;
  padding-top: 4px;
}
#social .field-3col .remove-social i {
  font-size: 20px;
}
.add-social {
  border: solid 2px #2d2d2d;
  color: #2d2d2d;
  background-color: #ffffff;
  font-family: 'Jost';
  font-size: 15px;
  font-weight: 500;
  transition: inherit;
}
.add-social svg {
  vertical-align: -2px;
}
.add-social:focus {
  background-color: #2d2d2d;
  color: #ffffff;
}
.add-social:focus svg > * {
  fill: #ffffff;
}
.add-social:hover {
  background-color: #2d2d2d;
  color: #ffffff;
}
.add-social:hover svg > * {
  fill: #ffffff;
}
.field-file .preview {
  display: block;
  width: 151px;
  height: 151px;
  border: dashed 1px #979797;
  background-color: #ffeaec;
  margin-bottom: 0;
  position: relative;
  cursor: pointer;
}
.field-file .preview input {
  opacity: 0;
  visibility: hidden;
  position: absolute;
}
.field-file .preview img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: relative;
  z-index: 2;
}
.field-file .preview i {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 26px;
}
.field-file .field-note {
  font-family: 'Jost-Book';
  font-size: 12px;
  font-style: italic;
  color: #8d8d8d;
  margin-top: 6px;
}
.field-submit {
  margin-top: 30px;
}
.field-submit:hover {
  color: #ffffff;
}
.maps-wrap {
  position: fixed !important;
  top: 80px;
  left: 0;
  right: 0;
  bottom: 0;
  height: 100%;
  z-index: 99;
}
.maps-wrap #maps-view {
  height: 100%;
}
.maps-wrap #maps-view .gm-style-mtc {
  display: none;
}
.maps-wrap .places-item {
  margin-bottom: 0;
  width: 370px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  border-radius: 3px;
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.2);
  background-color: #ffffff;
  overflow: hidden;
}
.maps-wrap .places-item img {
  width: 120px;
  height: 150px;
  object-fit: cover;
}
.maps-wrap .places-item .places-item__thumb a:after {
  content: '';
  display: none;
}
.maps-wrap .places-item .places-item__info {
  position: static;
  padding: 15px;
  flex-grow: 1;
  position: relative;
}
.maps-wrap .places-item h3 {
  font-family: 'Jost-Book';
  font-size: 20px;
  color: #2d2d2d;
}
.maps-wrap .gm-style .gm-style-iw-c {
  padding-top: 0;
  padding-left: 0;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.2);
}
.maps-wrap .gm-style .gm-style-iw-d {
  overflow: inherit !important;
}
.maps-wrap .gm-style .gm-style-iw-t::after {
  content: '';
  display: none !important;
}
.maps-wrap .places-item__category {
  font-family: 'Jost-Book';
  font-size: 12px;
  color: #8d8d8d;
  text-transform: uppercase;
  margin-bottom: 4px;
}
.maps-wrap .places-item__number {
  font-family: 'Jost';
  font-size: 15px;
  font-weight: 500;
}
.maps-wrap .places-item__number svg {
  margin-left: 2px;
}
.maps-wrap .places-item__meta {
  position: absolute;
  left: 15px;
  right: 15px;
  bottom: 15px;
}
.maps-wrap .places-item__meta .places-item__count {
  color: #5d5d5d;
  font-family: 'Jost-Book';
  font-size: 15px;
  margin-left: 5px;
}
.maps-wrap .places-item__meta .places-item__currency {
  color: #2d2d2d;
  font-family: 'Jost-Book';
  font-size: 16px;
}
.maps-wrap .gm-ui-hover-effect {
  top: 5px !important;
  right: 5px !important;
}
.maps-wrap .gm-ui-hover-effect img {
  width: 16px !important;
  height: 16px !important;
  margin: 0 !important;
  display: inline-block !important;
}
.maps-wrap .gm-bundled-control-on-bottom {
  bottom: auto;
  top: 0px;
}
.maps-wrap .gm-bundled-control-on-bottom .gm-svpc {
  display: none !important;
}
#place-map-filter map > * {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 99;
}
#place-map-filter .places-item {
  margin-bottom: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  border-radius: 3px;
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.2);
  background-color: #ffffff;
  overflow: hidden;
}
#place-map-filter .places-item img {
  width: 120px;
  height: 150px;
  object-fit: cover;
}
#place-map-filter .places-item .places-item__thumb a:after {
  content: '';
  display: none;
}
#place-map-filter .places-item .places-item__info {
  position: static;
  padding: 15px;
  flex-grow: 1;
  position: relative;
  min-width: 205px;
}
#place-map-filter .places-item h3 {
  font-family: 'Jost-Book';
  font-size: 20px;
  color: #2d2d2d;
}
#place-map-filter .places-item__category {
  font-family: 'Jost-Book';
  font-size: 12px;
  color: #8d8d8d;
  text-transform: uppercase;
  margin-bottom: 4px;
}
#place-map-filter .places-item__number {
  font-family: 'Jost';
  font-size: 15px;
  font-weight: 500;
}
#place-map-filter .places-item__number svg {
  margin-left: 2px;
}
#place-map-filter .places-item__meta {
  position: absolute;
  left: 15px;
  right: 15px;
  bottom: 15px;
}
#place-map-filter .places-item__meta .places-item__count {
  color: #5d5d5d;
  font-family: 'Jost-Book';
  font-size: 15px;
  margin-left: 5px;
}
#place-map-filter .places-item__meta .places-item__currency {
  color: #2d2d2d;
  font-family: 'Jost-Book';
  font-size: 16px;
}
#place-map-filter .gm-ui-hover-effect {
  top: 5px !important;
  right: 5px !important;
}
#place-map-filter .gm-ui-hover-effect img {
  width: 16px !important;
  height: 16px !important;
  margin: 0 !important;
  display: inline-block !important;
}
#place-map-filter .gm-bundled-control-on-bottom {
  bottom: auto;
  top: 0px;
}
#place-map-filter .gm-bundled-control-on-bottom .gm-svpc {
  display: none !important;
}
#maps-view map > * {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 99;
}
.maps-button {
  position: absolute;
  left: 50px;
  top: 30px;
  z-index: 98;
}
.maps-button a {
  padding: 0 20px;
  display: inline-block;
  vertical-align: middle;
  height: 40px;
  line-height: 40px;
  background-color: #ffffff;
  font-family: 'Jost';
  font-size: 15px;
  font-weight: 500;
  border-radius: 20px;
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.2);
  margin-bottom: 0;
}
.maps-button a i {
  display: inline-block;
  vertical-align: -2px;
  margin-right: 8px;
  font-size: 20px;
}
.maps-button .field-select {
  display: inline-block;
  vertical-align: middle;
  height: 40px;
  line-height: 40px;
  background-color: #ffffff;
  font-family: 'Jost';
  font-size: 15px;
  font-weight: 500;
  border-radius: 20px;
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.2);
  margin-bottom: 0;
}
.maps-button .field-select i {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  bottom: auto;
  right: 15px;
  font-size: 12px;
  margin-top: -1px;
}
.maps-button select {
  border: 0;
  padding: 0 34px 0 20px;
}
.maps-button select:focus {
  outline-offset: 0;
  outline: 0;
}
.single .place-gallery {
  position: absolute;
  right: 50px;
  bottom: 40px;
}
.single .place-gallery a {
  padding: 0 17px;
  display: inline-block;
  height: 36px;
  line-height: 36px;
  border-radius: 3px;
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  border: solid 1px #000000;
  margin-right: 8px;
  color: #ffffff;
  transition: all 0s;
  background-color: #000000;
  font-size: 15px;
  font-family: 'Jost-Book';
  font-weight: normal;
  transition: all 0.3s;
}
.single .place-gallery a:hover {
  background-color: #1d1d55;
  border-color: #1d1d55;
  color: #ffffff;
}
.single .place-gallery svg {
  vertical-align: -3px;
  display: inline-block;
  margin-right: 3px;
}
.single .place-gallery svg > * {
  transition: all 0.3s;
}
.single .breadcrumbs a {
  color: #ffffff;
  font-size: 14px;
  font-family: 'Jost-Book';
  font-weight: normal;
}
.single .place__box--npd {
  border-bottom: 0;
}
.single .place__box--npd h1 {
  color: #ffffff;
  font-weight: 500;
}
.single .reviews_count {
  color: #ffffff;
}
.single .place__currency {
  color: #ffffff;
}
.single .place__category a {
  color: #ffffff;
  font-size: 15px;
}
.single .hightlight-grid {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  align-items: center;
}
.single .hightlight-grid .hightlight-count {
  margin-right: 70px;
  font-family: 'Jost-Book';
  font-size: 16px;
  color: #1d1d55;
  white-space: nowrap;
}
.single .hightlight-grid .place__amenities {
  text-align: center;
  display: block;
  margin-right: 66px;
}
.single .hightlight-grid .place__amenities img {
  width: auto;
  height: 28px;
  object-fit: cover;
}
.single .hightlight-grid .place__amenities:nth-child(4) {
  margin-right: 35px;
}
.single .hightlight-grid .place__amenities span {
  display: block;
  margin-left: 0;
  margin-top: 15px;
}
.single .hightlight-grid .place__button {
  flex-grow: 1;
}
.single .place__box-overview .place__desc {
  font-size: 18px;
  line-height: 1.56;
  margin-bottom: 20px;
  position: relative;
  height: 137px;
  overflow: hidden;
  transition: all 0.3s;
}
.single .place__box-overview .place__desc:after {
  content: '';
  position: absolute;
  object-fit: contain;
  background-image: -webkit-linear-gradient(rgba(255, 255, 255, 0), #ffffff);
  background-image: -o-linear-gradient(rgba(255, 255, 255, 0), #ffffff);
  background-image: linear-gradient(rgba(255, 255, 255, 0), #ffffff);
  height: 53px;
  left: 0;
  bottom: 0;
  right: 0;
  opacity: 1;
  visibility: visible;
  transition: all 0.3s;
}
.single .place__box-overview .place__desc.open {
  height: 100%;
}
.single .place__box-overview .place__desc.open:after {
  content: '';
  opacity: 0;
  visibility: hidden;
}
.single .place__box-overview .show-more {
  font-size: 15px;
  color: #1d1d55;
}
.single .place__box h3 {
  font-size: 20px;
  font-weight: 500;
}
.single .place__box-map .address {
  margin-top: 30px;
}
.single .place__box-map .address a {
  color: #1d1d55;
}
.single .open-table {
  width: 100%;
}
.single .open-table .day {
  font-family: 'Jost';
  font-size: 16px;
  font-weight: 500;
  line-height: 1.88;
  width: 20%;
}
.single .open-table .time {
  font-size: 16px;
}
.single .place__meta > div {
  position: relative;
}
.single .place__meta > div:after {
  content: '';
  width: 4px;
  height: 4px;
  background-color: #ffffff;
  opacity: 0.6;
  display: inline-block;
  border-radius: 50%;
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  right: -14px;
  top: 50%;
  transform: translateY(-50%);
  position: absolute;
}
.single .place__meta > div:last-child:after {
  content: '';
  display: none;
}
.single-01 .place__left {
  margin-top: -125px;
  padding-right: 30px;
}
.single-02 .place__left {
  padding-right: 30px;
}
.single-02 .breadcrumbs a {
  color: #8d8d8d;
  font-size: 14px;
  font-family: 'Jost-Book';
  font-weight: normal;
}
.single-02 .place__box--npd {
  border-bottom: 0;
}
.single-02 .place__box--npd h1 {
  color: #2d2d2d;
  font-weight: 500;
}
.single-02 .reviews_count {
  color: #5d5d5d;
}
.single-02 .place__currency {
  color: #5d5d5d;
}
.single-02 .place__category a {
  color: #5d5d5d;
  font-size: 15px;
}
.single-02 .place__meta > div:after {
  background-color: #dddddd;
}
.review-form .stars {
  display: inline-block;
  margin-left: 8px;
}
.review-form .stars a:hover ~ a svg > * {
  fill: #dddddd;
}
.review-form .stars:hover svg > * {
  fill: #1d1d55;
}
.review-form .stars.selected a.active svg > * {
  fill: #1d1d55;
}
.review-form .stars.selected a.active ~ a svg > * {
  fill: #dddddd;
}
.review-form .stars.selected a:not(.active) svg > * {
  fill: #1d1d55;
}
.review-form .rate {
  margin-bottom: 15px;
}
.review-form .field-textarea {
  border-radius: 3px;
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  border: solid 1px #eeeeee;
  background-color: #ffffff;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  align-items: center;
  padding: 35px;
}
.review-form .field-textarea .author-avatar {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  object-fit: cover;
}
.review-form .field-textarea textarea {
  flex-grow: 1;
  border: 0;
  font-style: normal;
  padding: 0 0 0 20px;
  resize: none;
  height: 23px;
  transition: all 0.3s;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  align-items: center;
  vertical-align: middle;
}
.review-form .field-textarea textarea:focus {
  height: 92px;
}
.review-form textarea::-webkit-input-placeholder {
  font-family: 'Jost-Book';
  font-size: 15px;
  line-height: 1.53;
  color: #8d8d8d;
}
.review-form textarea:-ms-input-placeholder {
  font-family: 'Jost-Book';
  font-size: 15px;
  line-height: 1.53;
  color: #8d8d8d;
}
.review-form textarea::placeholder {
  font-family: 'Jost-Book';
  font-size: 15px;
  line-height: 1.53;
  color: #8d8d8d;
}
.popup-wrap.more-height {
  overflow-y: auto;
  padding-bottom: 1px;
  padding-top: 1px;
}
.popup-wrap {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 999;
}
.popup-wrap .popup-bg {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7);
}
.popup-wrap .popup-middle {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  left: 15px;
  right: 15px;
  background-color: #ffffff;
  max-width: 570px;
  margin: 0 auto;
  padding: 30px;
  z-index: 100;
  border-radius: 3px;
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.2);
}
.popup-wrap .popup-middle h3 {
  margin-bottom: 0;
}
.popup-wrap .popup-close {
  position: absolute;
  right: 30px;
  top: 30px;
  cursor: pointer;
}
.popup-wrap .hightlight-flex {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-left: -45px;
  margin-right: -45px;
}
.popup-wrap .hightlight-flex .place__amenities {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 25%;
  flex: 0 0 25%;
  max-width: 25%;
  margin-right: 0 !important;
  margin-top: 35px;
  padding: 0 30px;
}
.flex-inline {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.grid {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-left: -15px;
  margin-right: -15px;
}
.grid .grid-item {
  padding-left: 15px;
  padding-right: 15px;
}
.company-info {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  align-items: center;
}
.company-info > img {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 570px;
  flex: 0 0 570px;
  max-width: 570px;
}
.company-info .ci-content {
  padding: 0 60px;
}
.company-info .ci-content span {
  font-family: 'Jost-Book';
  font-size: 16px;
  line-height: 23px;
  color: #5d5d5d;
  margin-bottom: 10px;
}
.company-info .ci-content h2 {
  font-family: 'Jost';
  font-size: 28px;
  color: #2d2d2d;
  font-weight: 500;
  margin-bottom: 30px;
  line-height: 39px;
}
.company-info .ci-content p {
  font-size: 16px;
  line-height: 23px;
  margin-bottom: 15px;
}
.company-info .ci-content p:last-child {
  margin-bottom: 0;
}
.our-team {
  padding: 60px 0;
}
.join-team {
  padding-bottom: 60px;
}
.join-team .jt-content p {
  margin-bottom: 40px;
}
.join-team .jt-content a {
  padding: 0 48px;
  height: 50px;
  line-height: 50px;
  border-radius: 25px;
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  font-size: 16px;
}
.site-contact {
  position: relative;
}
.site-contact:before {
  content: '';
  position: absolute;
  background-color: #ffeaec;
  width: 50%;
  top: 0;
  bottom: 0;
  right: 0;
}
.site-contact h2 {
  font-size: 24px;
  font-weight: 500;
  margin-bottom: 30px;
}
.site-contact .contact-box {
  margin-bottom: 30px;
}
.site-contact .contact-box a {
  font-family: 'Jost';
  font-size: 15px;
  font-weight: 500;
  color: #1d1d55;
}
.site-contact h3 {
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 15px;
}
.site-contact p {
  font-size: 15px;
  color: #5d5d5d;
  margin-bottom: 15px;
}
.grid.grid-6 .grid-item {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 16.667%;
  flex: 0 0 16.667%;
  max-width: 16.667%;
}
.grid.grid-5 .grid-item {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 20%;
  flex: 0 0 20%;
  max-width: 20%;
}
.grid.grid-4 .grid-item {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 25%;
  flex: 0 0 25%;
  max-width: 25%;
}
.grid.grid-3 .grid-item {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 33.333%;
  flex: 0 0 33.333%;
  max-width: 33.333%;
}
.grid.grid-2 .grid-item {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 50%;
  flex: 0 0 50%;
  max-width: 50%;
}
.form-underline .field-inline {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-left: -15px;
  margin-right: -15px;
}
.form-underline .field-inline .field-input {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 50%;
  flex: 0 0 50%;
  max-width: 50%;
  padding-left: 15px;
  padding-right: 15px;
}
.form-underline textarea {
  width: 100%;
  height: 90px;
  border-radius: 0;
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  border: 0;
  padding: 0;
  border-bottom: 2px solid #2d2d2d;
  margin-bottom: 20px;
  font-style: normal;
  resize: none;
  background-color: inherit;
}
.form-underline textarea::-webkit-input-placeholder {
  font-size: 15px;
  font-style: normal;
  color: #8d8d8d;
  font-family: 'Jost-Book';
}
.form-underline textarea:-ms-input-placeholder {
  font-size: 15px;
  font-style: normal;
  color: #8d8d8d;
  font-family: 'Jost-Book';
}
.form-underline textarea::placeholder {
  font-size: 15px;
  font-style: normal;
  color: #8d8d8d;
  font-family: 'Jost-Book';
}
.form-underline input {
  width: 100%;
  height: 40px;
  line-height: 40px;
  border-radius: 0;
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  border: 0;
  padding: 0;
  border-bottom: 2px solid #2d2d2d;
  margin-bottom: 20px;
  font-style: normal;
  background-color: inherit;
}
.form-underline input::placeholder {
  font-size: 15px;
  font-style: normal;
  color: #8d8d8d;
  font-family: 'Jost-Book';
}
.form-underline input:-ms-input-placeholder {
  font-size: 15px;
  font-style: normal;
  color: #8d8d8d;
  font-family: 'Jost-Book';
}
.form-underline input::-ms-input-placeholder {
  font-size: 15px;
  font-style: normal;
  color: #8d8d8d;
  font-family: 'Jost-Book';
}
.form-underline input[type='submit'] {
  border-radius: 20px;
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  background-color: #1d1d55;
  color: #ffffff;
  margin-bottom: 0;
  cursor: pointer;
  border: 0;
}
.form-underline input[type='checkbox'] {
  width: auto;
  line-height: normal;
  height: auto;
  position: absolute;
  opacity: 0;
  visibility: hidden;
}
.form-underline .field-select {
  margin-bottom: 30px;
}
.form-underline .field-select .sl-icon {
  position: absolute;
  top: 6px;
  left: 0;
}
.form-underline .field-select .sl-icon i {
  font-size: 24px;
  vertical-align: 3px;
}
.form-underline .field-select select {
  width: 100%;
  border: 0;
  padding: 0;
  border-bottom: 2px solid #2d2d2d;
  height: 40px;
  line-height: 40px;
  color: #8d8d8d;
  padding-left: 30px;
}
.form-underline .field-select select:focus {
  border: 0;
  box-shadow: none;
  outline-offset: 0;
  outline: 0;
  border-bottom: 2px solid #2d2d2d;
}
.field-sub {
  padding: 30px;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.1);
  display: none;
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  background-color: #ffffff;
  z-index: 9;
}
.field-sub li {
  text-align: left;
  list-style: none;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  justify-content: space-between;
  vertical-align: middle;
  margin-bottom: 20px;
}
.field-sub li:last-child {
  margin-bottom: 0;
}
.field-date .input-group-append {
  display: none;
}
.gj-picker-bootstrap {
  border: 0;
  background-color: #ffffff;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.1);
  border-radius: 0;
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  margin-top: -3px;
}
.gj-picker-bootstrap table {
  width: 100%;
}
.gj-picker-bootstrap td div {
  border-radius: 50%;
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  width: 32px !important;
  height: 32px !important;
  line-height: 32px !important;
}
.gj-picker-bootstrap td div:hover {
  border-radius: 50% !important;
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  background-color: #ffeaec !important;
}
.gj-picker-bootstrap td.today div {
  background-color: #1d1d55 !important;
  color: #ffffff !important;
}
.field-time .field-sub {
  max-height: 300px;
  overflow: auto;
}
.field-time .field-sub::-webkit-scrollbar {
  width: 0;
}
.contact-main .page-title {
  margin-bottom: 0px;
}
.contact-main .contact-text {
  padding: 60px 0;
}
.contact-main .contact-form {
  padding-top: 60px;
  padding-bottom: 60px;
  padding-left: 45px;
  max-width: 340px;
}
.contact-main .contact-form .field-submit {
  margin-top: 0;
}
.error-wrap {
  margin: 100px auto;
  text-align: center;
  max-width: 450px;
}
.error-wrap h2 {
  font-size: 48px;
  font-weight: 500;
  margin-bottom: 15px;
}
.error-wrap b {
  font-family: 'Jost';
  font-size: 24px;
  font-weight: 500;
  display: block;
  color: #2d2d2d;
  margin-bottom: 15px;
}
.error-wrap a {
  color: #1d1d55;
}
.account {
  position: relative;
}
.account img {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  vertical-align: middle;
  display: inline-block;
}
.account span {
  font-family: 'Jost';
  vertical-align: middle;
  display: inline-block;
  font-size: 15px;
  font-weight: 500;
  margin-left: 5px;
}
.account span i {
  font-size: 12px;
}
.account svg {
  vertical-align: 3px;
  display: inline-block;
}
.account-sub {
  position: absolute;
  display: none;
  top: 100%;
  left: -20px;
  margin-top: 10px;
  text-align: left;
  z-index: 9;
  background-color: #ffffff;
  width: 130px;
  box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.1);
}
.account-sub ul {
  padding: 10px 0;
}
.account-sub li {
  list-style: none;
  line-height: normal;
  height: auto;
}
.account-sub li a {
  display: block;
  padding: 10px 20px;
  font-family: 'Jost';
  font-size: 15px;
  font-weight: 500;
}
.account-sub li:last-child {
  padding-top: 10px;
  margin-top: 10px;
  border-top: 1px solid #eeeeee;
}
.account-sub li.active a {
  color: #1d1d55;
}
.member-menu {
  background-color: #ec1c38;
  margin-bottom: 30px;
}
.member-menu li {
  display: inline-block;
  list-style: none;
  padding: 0 15px;
}
.member-menu li a {
  font-size: 15px;
  font-weight: 500;
  color: #ffffff;
  text-transform: uppercase;
  height: 60px;
  line-height: 60px;
  display: block;
  font-family: 'Jost';
}
.member-menu li:first-child {
  padding-left: 0;
}
.member-menu li.active a {
  color: #1d1d55;
}
.member-wrap {
  margin-bottom: 60px;
}
.member-wrap .member-avatar {
  margin-bottom: 30px;
}
.member-wrap .member-avatar img {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  object-fit: cover;
}
.member-wrap .member-avatar label {
  display: inline-block;
  margin-left: 12px;
  vertical-align: middle;
  color: #1d1d55;
}
.member-wrap .member-avatar label input {
  position: absolute;
  opacity: 0;
  visibility: hidden;
  width: auto;
}
.member-wrap h1 {
  font-size: 28px;
  font-weight: 500;
  margin-bottom: 30px;
}
.member-wrap h3 {
  font-size: 15px;
  font-weight: 500;
  color: #8d8d8d;
  text-transform: uppercase;
  margin-bottom: 20px;
}
.member-wrap form {
  max-width: 300px;
  margin-bottom: 30px;
}
.member-wrap form input[type='file'] {
  border-bottom: 0;
}
.member-wrap form .field-submit {
  margin-top: 0;
}
.member-wrap form input[type='submit'] {
  width: auto;
  padding: 0 25px;
}
.member-wrap form input[type='submit']:focus {
  box-shadow: none;
  outline: 0;
  outline-offset: 0;
}
.member-wrap label {
  font-family: 'Jost';
  font-size: 15px;
  font-weight: 500;
  color: #2d2d2d;
}
.owner-content {
  background-color: #ededff;
  padding-bottom: 60px;
}
.owner-content .member-wrap {
  margin-bottom: 0;
}
.member-wrap-top {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 30px;
}
.member-wrap-top h2 {
  font-size: 28px;
  font-weight: 500;
}
.member-wrap-top p {
  font-size: 16px;
  font-weight: 500;
  font-family: 'Jost';
}
.member-wrap-top p a {
  color: #1d1d55;
}
.member-wrap-top p a:hover {
  opacity: 0.6;
}
.upgrade-box {
  border-radius: 16px;
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  background-color: #ec1c38;
  padding: 30px 50px;
  position: relative;
  margin-bottom: 30px;
}
.upgrade-box h1 {
  font-size: 36px;
  font-weight: 500;
  margin-bottom: 5px;
  line-height: 49px;
  color: #fff;
}
.upgrade-box p {
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 20px;
  font-family: 'Jost';
  color: #fff;
}
.upgrade-box img {
  position: absolute;
  top: 0;
  right: 64px;
  bottom: 0;
  height: 100%;
}
.upgrade-box .close {
  position: absolute;
  top: 25px;
  right: 25px;
}
.upgrade-box .close i {
  font-size: 24px;
  color: #fff;
}
.upgrade-box .close i:hover {
  color: #1d1d55;
}
.member-place-top {
  vertical-align: middle;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 25px;
}
.member-place-top h1 {
  font-size: 28px;
  font-weight: 500;
}
.member-statistical {
  margin-bottom: 30px;
}
.member-statistical .item {
  border-radius: 10px;
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  border: solid 1px #eeeeee;
  background-color: #ffffff;
  padding: 25px;
  text-align: center;
}
.member-statistical .item h3 {
  font-size: 15px;
  font-weight: 500;
  line-height: 1.6;
  color: #5d5d5d;
  text-transform: uppercase;
  margin-bottom: 6px;
}
.member-statistical .item .number {
  font-size: 36px;
  font-weight: 500;
  line-height: 1.61;
  font-family: 'Jost';
  color: #2d2d2d;
}
.member-statistical .item .line {
  border-radius: 5px;
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  background-color: #1d1d55;
  display: block;
  width: 100px;
  height: 5px;
  margin: 0 auto;
}
.member-statistical .item.blue .line {
  background-color: #1d1d55;
}
.member-statistical .item.green .line {
  background-color: #52e707;
}
.member-statistical .item.yellow .line {
  background-color: #ffd13d;
}
.member-statistical .item.purple .line {
  background-color: #5f4fff;
}
.ob-item {
  border-radius: 10px;
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  border: solid 1px #eeeeee;
  background-color: #ffffff;
  padding: 25px;
}
.ob-item .ob-head {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 20px;
  border-bottom: 1px solid #eeeeee;
}
.ob-item .ob-head h3 {
  margin-bottom: 0;
  font-size: 15px;
  font-weight: 500;
  line-height: 1.6;
  color: #5d5d5d;
}
.ob-item .ob-head h3 span {
  color: #1d1d55;
}
.ob-item .ob-head a {
  font-size: 15px;
  font-weight: 500;
  color: #1d1d55;
  font-family: 'Jost';
}
.ob-item .ob-head a.clear-all {
  color: #e74807;
}
.ob-item .ob-content li {
  list-style: none;
  padding: 20px 0;
  border-bottom: 1px solid #eeeeee;
  position: relative;
}
.ob-item .ob-content p {
  font-size: 16px;
  line-height: 1.5;
  margin-bottom: 10px;
}
.ob-item .ob-content p b {
  margin-right: 5px;
  display: inline-block;
  color: #5d5d5d;
  font-weight: 500;
  font-family: 'Jost';
}
.ob-item .ob-content li.pending span {
  color: #ffcc00;
}
.ob-item .ob-content li.approve span {
  color: #2dcb48;
}
.ob-item .ob-content li.cancel span {
  color: #e74807;
}
.ob-item .ob-content p.place {
  color: #2d2d2d;
  font-weight: 500;
  font-family: 'Jost';
}
.ob-item .ob-content p.status {
  margin-bottom: 0;
}
.ob-item .ob-content p.status span {
  font-size: 16px;
  font-weight: 500;
  font-family: 'Jost';
}
.ob-item .ob-content a.more {
  position: absolute;
  top: 40px;
  right: 20px;
}
.ob-item .ob-content a.more i {
  font-size: 24px;
  color: #1d1d55;
}
.ob-item ul {
  height: 378px;
  overflow-y: scroll;
  margin-bottom: 0 !important;
}
.ob-item ul::-webkit-scrollbar {
  width: 6px;
  background-color: transparent;
}
.ob-item ul::-webkit-scrollbar-thumb {
  background-color: #eeeeee;
  border-radius: 5px;
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
}
.noti-item {
  padding-left: 20px !important;
}
.noti-item p {
  font-size: 16px;
  line-height: 1.5;
  font-family: 'Jost';
  margin-bottom: 10px;
}
.noti-item span {
  font-size: 12px;
  color: #8d8d8d;
  font-style: italic;
  margin-right: 10px;
}
.noti-item a {
  font-size: 12px;
  color: #8d8d8d;
  font-style: italic;
  margin-right: 10px;
  color: #e74807;
}
.noti-item:before {
  content: '';
  width: 4px;
  height: 4px;
  border-radius: 50%;
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  background-color: #d8d8d8;
  position: absolute;
  top: 50%;
  margin-top: -2px;
  left: 0;
  display: block;
}
.noti-item.unread:before {
  background-color: #1d1d55;
}
.member-filter {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  justify-content: space-between;
  margin-bottom: 30px;
}
.member-filter .mf-left {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 50%;
  flex: 0 0 50%;
  max-width: 50%;
}
.member-filter .mf-right form {
  width: 200px;
  padding-bottom: 0;
  border-bottom: 2px solid #2d2d2d;
}
.member-filter .mf-right form .site__search__icon {
  top: 6px;
}
.member-filter .mf-right input {
  padding-bottom: 0;
  height: 31px;
  line-height: 31px;
  border-bottom: 0;
  margin-bottom: 1px;
  background-color: transparent;
}
.member-filter .field-select {
  max-width: 200px;
  width: 100%;
  display: inline-block;
  margin-right: 28px;
}
.member-filter .field-select:last-child {
  margin-right: 0;
}
.member-filter select {
  border-top: 0;
  border-left: 0;
  border-right: 0;
  border-bottom: 2px solid #2d2d2d;
  font-style: normal;
  height: 32px;
  line-height: 32px;
  padding: 0;
  width: 100%;
  box-shadow: inherit;
  background-image: initial;
}
.member-filter select:focus {
  outline: 0;
  outline-offset: 0;
  border-bottom-color: #1d1d55;
}
.member-place-list {
  width: 100%;
  border: 1px solid #eeeeee;
  margin-bottom: 30px;
  overflow: inherit;
  background-color: #ffffff;
}
.member-place-list .field-check {
  margin-bottom: 0;
}
.member-place-list th {
  padding: 30px 20px 20px;
  font-size: 15px;
  font-weight: 500;
  font-family: Jost;
  color: #5d5d5d;
  text-transform: uppercase;
  line-height: 1.6;
}
.member-place-list th:nth-child(1) {
  padding-left: 30px;
}
.member-place-list td {
  border-top: 1px solid #eeeeee;
  padding: 20px 20px;
  font-size: 16px;
  font-weight: 500;
  font-family: 'Jost';
}
.member-place-list td img {
  width: 70px;
  height: 70px;
  object-fit: cover;
}
.member-place-list td:nth-child(1) {
  padding-left: 30px;
  width: 5%;
}
.member-place-list td:nth-child(2) {
  width: 5%;
}
.member-place-list td:nth-child(3) {
  width: 12%;
}
.member-place-list td:nth-child(4) {
  width: 30%;
}
.member-place-list td b {
  font-size: 16px;
  font-weight: 500;
  font-family: Jost;
  color: #2d2d2d;
}
.member-place-list td i {
  font-size: 24px;
  margin-right: 10px;
  color: #5d5d5d;
}
.member-place-list td i:hover {
  color: #1d1d55;
}
.member-place-list td a.delete i {
  margin-right: 0;
}
.member-place-list td.pending {
  color: #ffcc00;
}
.member-place-list td.approved {
  color: #2dcb48;
}
.member-place-list td.cancel {
  color: #e74807;
}
.member-place-list.owner-booking td:nth-child(2) {
  width: 30%;
}
.member-place-list.owner-booking td:nth-child(3) {
  width: 20%;
}
.member-place-list.owner-booking td:nth-child(4) {
  width: 15%;
}
.member-place-list.owner-booking td.place-action {
  text-align: right;
}
.member-place-list.owner-booking td.place-action a {
  font-size: 15px;
  height: 40px;
  line-height: 38px;
  border-radius: 20px;
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  display: inline-block;
  text-align: center;
  padding: 0 15px;
  border: 1px solid;
  margin-left: 8px;
}
.member-place-list.owner-booking td.place-action .approve {
  border-color: #2dcb48;
  color: #2dcb48;
}
.member-place-list.owner-booking td.place-action .approve:hover {
  background-color: #2dcb48;
  color: #ffffff;
}
.member-place-list.owner-booking td.place-action .pending {
  border-color: #ffcc00;
  color: #ffcc00;
}
.member-place-list.owner-booking td.place-action .pending:hover {
  background-color: #ffcc00;
  color: #ffffff;
}
.member-place-list.owner-booking td.place-action .cancel {
  border-color: #e74807;
  color: #e74807;
}
.member-place-list.owner-booking td.place-action .cancel:hover {
  background-color: #e74807;
  color: #ffffff;
}
.member-place-list.owner-booking td.place-action .detail {
  border-color: #2d2d2d;
  color: #2d2d2d;
}
.member-place-list.owner-booking td.place-action .detail:hover {
  background-color: #2d2d2d;
  color: #ffffff;
}
.place-action {
  position: relative;
}
.box-option {
  display: none;
  position: absolute;
  box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.1);
  width: 130px;
  z-index: 9;
  background-color: #ffffff;
  padding: 20px;
  top: 80px;
  left: 0;
}
.box-option li {
  list-style: none;
  margin-bottom: 20px;
}
.box-option li:last-child {
  margin-bottom: 0;
}
.box-option li i {
  width: 25px;
  text-align: center;
  margin-right: 10px;
  font-size: 20px;
}
.member-wishlist-wrap h1 {
  font-family: Jost;
  font-size: 28px;
  font-weight: 500;
  margin-bottom: 30px;
}
.member-wishlist-wrap .places-item .place-item__addwishlist {
  opacity: 1;
}
.gm-style .gm-style-iw-c {
  padding: 0;
  border-radius: 0;
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  max-width: 325px !important;
  max-height: inherit !important;
}
.gm-style .gm-style-iw-d {
  overflow: auto !important;
  max-height: inherit !important;
  max-width: inherit !important;
}
.mw-box {
  margin-bottom: 30px;
}
.mw-box h2 {
  font-size: 24px;
  font-weight: 500;
  margin-bottom: 20px;
}
.alert {
  max-width: 600px;
  margin: 0 auto 30px;
  padding: 0;
}
.alert h2 {
  font-size: 24px;
  font-weight: 500;
  text-align: center;
  margin-bottom: 20px;
}
.alert p {
  font-family: Jost;
  font-weight: 500;
  padding: 22px 22px 22px 66px;
  border: solid 1px #20d706;
  border-radius: 3px;
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  position: relative;
}
.alert i {
  display: inline-block;
  vertical-align: middle;
  position: absolute;
  top: 23px;
  left: 22px;
  font-size: 20px;
}
.alert a {
  color: #1d1d55;
}
.alert.alert-success {
  color: #5d5d5d;
  background-color: inherit;
  border: 0;
}
.alert.alert-success i {
  color: #20d706;
}
.alert.alert-error p {
  border: solid 1px #ff2d55;
}
.alert.alert-error i {
  color: #ff2d55;
}
.alert.alert-warning {
  color: #5d5d5d;
  background-color: inherit;
  border: 0;
}
.alert.alert-warning p {
  border: solid 1px #f2d23d;
}
.alert.alert-warning i {
  color: #f2d23d;
}
.site-notification {
  padding: 60px 0;
}
.home-header {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  background-color: transparent;
  z-index: 9;
}
.home-header .minicart__open > i {
  color: #ffffff;
}
.home-header .minicart__open > span {
  color: #ffffff;
}
.home-header .minicart__open:hover > i {
  color: #1d1d55;
}
.home-header .minicart__open:hover > span {
  color: #1d1d55;
}
.site-main.overflow {
  overflow: hidden;
}
.site-main.layout-list .place-thumb-slider .slick-item:not(:first-child) {
  display: block;
}
.site-main.layout-list .place-thumb-sliders {
  position: relative;
}
.site-main.layout-list .place-thumb-sliders .place-slider__nav {
  display: block;
}
.site-main.layout-list .place-thumb-sliders .slick-nav__prev {
  background-color: transparent;
  width: auto;
  color: #fff;
  left: 20px;
}
.site-main.layout-list .place-thumb-sliders .slick-nav__next {
  background-color: transparent;
  width: auto;
  color: #fff;
  right: 20px;
}
.site-main.layout-list .featured {
  height: 24px;
  line-height: 24px;
  display: inline-block;
  padding: 0 10px;
  border-radius: 3px;
  background-color: #ffc108;
  font-size: 15px;
  font-weight: 500;
  color: #fff;
  position: absolute;
  right: 0;
  top: 20px;
  z-index: 9;
}
.home-main .site-banner {
  height: 100vh;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  position: relative;
}
.home-main .site-banner:after {
  content: '';
  background-image: 'url(/images/assets/mask-1.svg)';
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  width: 150%;
  display: block;
  position: absolute;
  left: -26%;
  right: 0;
  bottom: -2px;
  background-size: 100%;
  height: 75px;
}
.home-main .site-banner__content {
  text-align: center;
}
.home-main .site-banner__content h1 {
  font-size: 72px;
  margin-bottom: 10px;
  font-family: Jost;
  font-weight: 500;
  color: #ffffff;
}
.home-main .site-banner__content p {
  font-size: 24px;
  color: #ffffff;
  margin-bottom: 30px;
  font-family: Jost;
  font-weight: 300;
}
.home-main .site-banner__content .site-banner__search {
  margin: 0 auto;
}
.home-main .site-banner__content .site-banner__search__input {
  padding-left: 55px;
}
.trending {
  padding-bottom: 60px;
  background-color: #ededff;
  padding: 60px 0;
}
.trending .slick-nav > div {
  opacity: 1;
  left: -25px;
  box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.1);
}
.trending .slick-nav > div.slick-nav__next {
  right: -25px;
  left: auto;
}
.trending .slick-dots {
  bottom: -40px;
}
.trending .slick-dots button:before {
  width: 8px;
  height: 8px;
  background-color: #d8d8d8;
}
.trending .slick-dots .slick-active button:before {
  background-color: #1d1d55;
}
.title-border-bottom {
  font-family: Jost;
  font-size: 28px;
  font-weight: 500;
  line-height: 1.61;
  position: relative;
  margin-bottom: 55px;
}
.title-border-bottom:after {
  content: '';
  position: absolute;
  bottom: -15px;
  height: 1px;
  width: 80px;
  background-color: #1d1d55;
  margin: 0 auto;
  left: 0;
  right: 0;
}
.title-while {
  color: #ffffff;
  opacity: 1;
}
@keyframes shimmer {
  100% {
    transform: translateX(100%);
  }
}
.place-item.layout-02 .place-inner {
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.05);
  position: relative;
  border-radius: 15px;
  -moz-border-radius: 15px;
  -webkit-border-radius: 15px;
  -ms-border-radius: 15px;
  -o-border-radius: 15px;
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  overflow: hidden;
}
.place-item.layout-02 .featured {
  display: none;
}
.place-item.layout-02 .place-thumb {
  position: relative;
  display: block;
}
.place-item.layout-02 .place-thumb .entry-thumb {
  overflow: hidden;
  display: block;
}
.place-item.layout-02 .place-thumb img {
  -webkit-transform: scale(1);
  -moz-transform: scale(1);
  -o-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1);
  -webkit-transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
  height: 210px;
  width: 100%;
  object-fit: cover;
}
.place-item.layout-02 .place-thumb .btn-add-to-wishlist {
  line-height: 0;
  position: absolute;
  top: 20px;
  right: 20px;
  width: 32px;
  height: 32px;
  border-radius: 30px;
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  background: #ffffff;
  align-items: center;
  justify-content: center;
}
.place-item.layout-02 .place-thumb .entry-category {
  position: absolute;
  top: 20px;
  left: 20px;
}
.place-item.layout-02 .place-thumb .entry-category img {
  width: 32px;
  height: auto;
}
.place-item.layout-02 .feature {
  position: absolute;
  left: 20px;
  bottom: 0;
  background-color: #ffcc01;
  border-radius: 5px;
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  height: 28px;
  line-height: 28px;
  font-family: Jost;
  font-size: 15px;
  font-weight: 500;
  padding: 0 10px;
  color: #ffffff;
  transform: translateY(50%);
}
.place-item.layout-02 .entry-detail {
  background: #ffffff;
  border: solid 1px #eeeeee;
  border-top: 0;
  border-bottom-right-radius: 15px;
  border-bottom-left-radius: 15px;
  padding: 20px;
}
.place-item.layout-02 .entry-head {
  font-size: 16px;
  line-height: 1.5;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 5px;
}
.place-item.layout-02 .entry-head .place-city {
  position: relative;
  padding-left: 10px;
  margin-left: 8px;
}
.place-item.layout-02 .entry-head .place-city:before {
  content: '';
  position: absolute;
  left: 0;
  bottom: 9px;
  width: 3px;
  height: 3px;
  background-color: #d8d8d8;
}
.place-item.layout-02 .place-type {
  font-size: 16px;
  line-height: 1.5;
  text-transform: inherit;
  margin-bottom: 0;
}
.place-item.layout-02 .place-title {
  font-size: 20px;
  font-weight: 500;
  line-height: 1.4;
  margin-bottom: 25px;
}
.place-item.layout-02 .entry-bottom {
  font-weight: 500;
  font-family: Jost;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.place-item.layout-02 .place-preview {
  font-size: 15px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.place-item.layout-02 .place-preview .place-rating {
  margin-right: 3px;
  color: #1d1d55;
}
.place-item.layout-02 .entry-category {
  width: 32px;
  height: 32px;
  line-height: 32px;
  text-align: center;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.05);
  border-radius: 16px;
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  padding: 0;
  transition: all 0.3s ease-in-out 0s;
  -moz-transition: all 0.3s ease-in-out 0s;
  -o-transition: all 0.3s ease-in-out 0s;
  -webkit-transition: all 0.3s ease-in-out 0s;
  -ms-transition: all 0.3s ease-in-out 0s;
}
.place-item.layout-02 .entry-category span {
  color: #ffffff;
  display: none;
}
.place-item.layout-02 .entry-category i {
  color: #ffffff;
  margin-right: 0px;
}
.place-item.layout-02 .entry-category:hover {
  width: auto;
  border-radius: 16px;
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  padding: 0 10px;
}
.place-item.layout-02 .entry-category:hover span {
  display: inline-block;
}
.place-item.layout-02 .entry-category:hover i {
  margin-right: 5px;
}
.place-item.layout-02 .entry-category.rosy-pink {
  background-color: #f0626c;
}
.place-item.layout-02 .entry-category.purple {
  background-color: #d763d7;
}
.place-item.layout-02 .entry-category.blue {
  background-color: #5b5ff9;
}
.place-item.layout-02 .entry-category.orange {
  background-color: #ffb44f;
}
.place-item.layout-02 .entry-category.charcoal-purple {
  background-color: #846fcd;
}
.place-item.layout-02 .entry-category.green {
  background-color: #78cc58;
}
.place-item.layout-02 .open-now,
.place-item.layout-02 .close-now {
  font-family: Jost;
  font-size: 15px;
  font-weight: 500;
  color: #2dcb48;
  display: flex;
  align-items: center;
  margin-bottom: 22px;
}
.place-item.layout-02 .open-now i,
.place-item.layout-02 .close-now i {
  font-size: 24px;
  margin-right: 2px;
}
.place-item.layout-02 .close-now {
  color: #f43319;
}
.place-item.layout-02 .author {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  border: 2px solid #ffffff;
  display: block;
  position: absolute;
  bottom: -18px;
  right: 20px;
}
.place-item.layout-02 .author img {
  height: auto;
  border-radius: 50%;
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -webkit-transform: scale(1);
  -moz-transform: scale(1);
  -o-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1);
}
.testimonial-sliders .slick-arrow {
  top: 40%;
}
.section-bg {
  background-color: #ffeaec;
}
.cuisine-slider .item {
  text-align: center;
}
.cuisine-slider .item a {
  display: block;
}
.cuisine-slider .item img {
  border-radius: 50%;
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  height: 170px;
  object-fit: cover;
}
.cuisine-slider .item span.title {
  font-family: Jost;
  font-size: 16px;
  font-weight: 500;
}
.cuisine-slider .item span.title span {
  margin-left: 5px;
}
.cuisine-slider .item span.hover-img {
  display: block;
  border-radius: 50%;
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  overflow: hidden;
  margin-bottom: 15px;
}
.hover-img img {
  -webkit-transform: scale(1);
  -moz-transform: scale(1);
  -o-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1);
  -webkit-transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
}
.hover-img:hover img {
  -webkit-transform: scale(1.1);
  -moz-transform: scale(1.1);
  -o-transform: scale(1.1);
  -ms-transform: scale(1.1);
  transform: scale(1.1);
}
.testimonials {
  padding: 60px 0 30px;
}
.menu-tab ul {
  margin-bottom: 20px;
}
.menu-tab li {
  list-style: none;
  display: inline-block;
  margin-right: 30px;
}
.menu-tab li a {
  color: #5d5d5d;
  text-transform: uppercase;
  font-family: Jost;
  font-size: 15px;
  font-weight: 500;
  position: relative;
  display: block;
}
.menu-tab li a:after {
  content: '';
  width: 100%;
  bottom: 0;
  left: 0;
  height: 1px;
  background-color: #2d2d2d;
  display: block;
  position: absolute;
  display: none;
}
.menu-tab li.active a {
  color: #2d2d2d;
}
.menu-tab li.active a:after {
  display: block;
}
.menu-tab li:hover a {
  color: #2d2d2d;
}
.menu-tab li:last-child {
  margin-right: 0;
}
.menu-tab .flex {
  flex-wrap: wrap;
  margin-left: -15px;
  margin-right: -15px;
  max-height: 350px;
  height: 100%;
  overflow: hidden;
  position: relative;
  margin-bottom: 30px;
  transition: all 0.3s;
}
.menu-tab .flex:after {
  content: '';
  position: absolute;
  object-fit: contain;
  background-image: -webkit-linear-gradient(rgba(255, 255, 255, 0), #ffffff);
  background-image: -o-linear-gradient(rgba(255, 255, 255, 0), #ffffff);
  background-image: linear-gradient(rgba(255, 255, 255, 0), #ffffff);
  height: 53px;
  left: 0;
  bottom: 0;
  right: 0;
  opacity: 1;
  visibility: visible;
  transition: all 0.3s;
}
.menu-tab .flex.open {
  height: 100%;
  max-height: 100%;
}
.menu-tab .flex.open:after {
  content: '';
  display: none;
}
.menu-wrap {
  display: none;
}
.menu-wrap > a {
  color: #1d1d55;
}
.menu-wrap.active {
  display: block;
}
.menu-item {
  width: 50%;
  padding: 0 15px;
  display: flex;
  align-items: center;
  position: relative;
  margin-bottom: 30px;
}
.menu-item img {
  width: 70px;
}
.menu-item .menu-info {
  width: calc(100% - 70px);
  padding-right: 35px;
  margin-left: 15px;
  position: relative;
}
.menu-item .menu-info h4 {
  font-family: Jost;
  font-size: 16px;
  font-weight: 500;
  color: #5d5d5d;
}
.menu-item .menu-info p {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.menu-item .price {
  position: absolute;
  top: -2px;
  right: 7px;
  font-family: Jost;
  font-size: 16px;
  font-weight: 500;
  color: #5d5d5d;
}
.menu-item:nth-last-child(1),
.menu-item:nth-last-child(2) {
  margin-bottom: 0;
}
.button-wrap {
  text-align: center;
  margin-top: 40px;
}
.place-item:hover .entry-thumb > img {
  -webkit-transform: scale(1.1);
  -moz-transform: scale(1.1);
  -o-transform: scale(1.1);
  -ms-transform: scale(1.1);
  transform: scale(1.1);
  -webkit-transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
}
.slider-pd30 {
  margin-left: -15px;
  margin-right: -15px;
}
.slider-pd30 .slick-slide > div {
  padding-left: 15px;
  padding-right: 15px;
}
.icon-heart > i {
  font-size: 24px;
}
.featured-slider.slick-slider img {
  height: 360px;
}
.testimonial-item {
  position: relative;
  padding: 30px 0 20px;
  text-align: center;
}
.testimonial-item img {
  max-width: 70px;
  width: 100%;
  height: auto;
  position: absolute;
  left: 50%;
  top: 0;
  -webkit-transform: translateX(-50%);
  -moz-transform: translateX(-50%);
  -o-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
}
.testimonial-item p {
  font-family: Jost;
  font-size: 18px;
  line-height: 1.44;
  margin-bottom: 20px;
}
.testimonial-item h4 {
  font-weight: 500;
  margin-bottom: 5px;
}
.testimonial-item span {
  font-family: Jost;
  font-size: 16px;
  font-style: italic;
  color: #9a9a9a;
}
.testimonial {
  padding: 60px 0;
}
.testimonial .slick-nav > div {
  top: 70px;
  left: -105px;
  width: 46px;
  height: 46px;
  box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.1);
  opacity: 1;
}
.testimonial .slick-nav .slick-nav__next {
  left: auto;
  right: -105px;
}
.testimonial .slick-sliders {
  max-width: 60%;
  margin: 0 auto;
}
.testimonial-control {
  height: 64px;
  max-width: 450px;
  margin: 0 auto;
}
.testimonial-control .tc-item {
  padding: 10px 0;
  text-align: center;
}
.testimonial-control .tc-item img {
  height: auto;
  width: 85px;
  height: 85px;
  opacity: 0.4;
  cursor: pointer;
  border-radius: 50%;
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  margin: 0 auto;
  -webkit-transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
}
.testimonial-control .tc-item img:hover {
  opacity: 1;
}
.testimonial-control .slick-current img {
  width: 85px;
  opacity: 1;
}
.btn-border {
  opacity: 0.9;
  border-radius: 20px;
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  display: inline-block;
  border: solid 1px #ffffff;
  margin: 0 auto;
}
.btn-border:hover {
  opacity: 1;
  background-color: #ffffff;
  color: #1d1d55;
}
.page-title.layout-2 {
  position: relative;
}
.page-title.layout-2:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: 40%;
  background-color: #1d1d55;
}
.page-title.layout-2:after {
  content: '';
  position: absolute;
  left: 0;
  bottom: 0px;
  right: 0;
  background-repeat: no-repeat;
  height: 400px;
  background-size: 800px 800px;
  background-image: radial-gradient(
    circle at 85% 3%,
    transparent calc(400px + 1px),
    #1d1d55 calc(400px + 2px)
  );
  background-position: top center;
}
.page-title.layout-2 .page-title__content {
  position: static;
  text-align: left;
  transform: inherit;
  margin-bottom: 100px;
}
.page-title.layout-2 .page-title__content p {
  font-size: 18px;
  font-weight: 400;
  font-style: inherit;
}
.page-title.layout-2 .intro__meta {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  flex-wrap: wrap;
}
.page-title.layout-2 .intro__meta__item {
  border-left: 0;
  padding-left: 0;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 33.33%;
  flex: 0 0 33.33%;
  max-width: 33.33%;
  color: #ffffff;
}
.page-title.layout-2 .intro__meta__item h3 {
  color: #ffffff;
}
.page-title.layout-2 .page-title-info {
  max-width: 40%;
  width: 100%;
  padding-top: 75px;
  position: relative;
  z-index: 2;
}
.page-title.layout-2 h1 {
  font-family: Jost;
  font-size: 80px;
  font-weight: 500;
}
.filter-list.open .filter-group {
  height: 100%;
}
.main-primary {
  flex: 1;
  padding-left: 30px;
  padding-right: 30px;
}
.main-primary .top-area {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 25px;
}
.main-primary .top-area .result-count {
  font-size: 16px;
  font-weight: 500;
  line-height: 1.5;
  color: #2d2d2d;
  font-family: 'Jost';
}
.main-primary .top-area-filter .place-layout a.active {
  color: #1d1d55;
}
.main-primary .top-area-filter .place-layout a {
  padding: 0 2px;
}
.main-primary .top-area-filter .place-layout i {
  font-size: 24px;
}
.main-primary .top-area-filter .clear {
  margin-left: 10px;
  color: #1d1d55;
  display: none;
}
.main-primary .top-area-filter .clear:hover {
  opacity: 0.7;
}
.main-primary .top-area-filter .filter-right {
  display: flex;
  align-items: center;
}
.main-primary .pagination {
  margin-bottom: 0;
  text-align: left;
}
.template-coming-soon .site-header {
  padding: 60px 0 15px;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  background-color: transparent;
}
.template-coming-soon .site-header .site__brand {
  line-height: 30px;
}
.template-coming-soon .site-main {
  height: 100vh;
  padding: 105px 0;
}
.template-coming-soon .site-main div {
  height: 100%;
  position: relative;
}
.template-coming-soon .site-main img {
  height: 100%;
}
.template-coming-soon .site-main .cs-info {
  height: auto;
}
.template-coming-soon .cs-info {
  position: absolute;
  top: 50%;
  left: 0;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  max-width: 400px;
}
.template-coming-soon .cs-info h1 {
  font-size: 58px;
  font-family: 'Jost-Semi';
  line-height: 1.28;
  color: #1a1a1a;
  margin-bottom: 10px;
}
.template-coming-soon .cs-info p {
  font-size: 18px;
  line-height: 1.61;
  margin-bottom: 30px;
}
.template-coming-soon .footer {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: transparent;
  border: 0;
  padding: 15px 0 60px;
}
.site-banner__search.cs-newletter .site-banner__search__icon {
  right: 25px;
  left: auto;
}
.site-banner__search.cs-newletter .site-banner__search__icon i {
  font-size: 32px;
  color: #1d1d55;
}
.site-banner__search.cs-newletter .site-banner__search__input {
  border: solid 1px #d8d8d8;
  padding: 0 55px 0 25px;
  line-height: 58px;
  height: 60px;
}
.footer-socials li {
  list-style: none;
  display: inline-block;
  margin-right: 20px;
}
.footer-socials li i {
  font-size: 32px;
}
.footer-socials li:last-child {
  margin-right: 0;
}
.template-coming-soon.layout-2 .cs-info h1 {
  font-family: 'Jost';
}
.template-coming-soon.layout-2 .cs-info h1 span {
  color: #1d1d55;
}
.template-coming-soon.layout-2 .cs-info p {
  margin-bottom: 20px;
}
.template-coming-soon.layout-2 .cs-info b {
  font-family: 'Jost';
  font-size: 16px;
  font-weight: 500;
  color: #5d5d5d;
}
.template-coming-soon.layout-2 .cs-info a {
  font-family: 'Jost';
  color: #1d1d55;
  font-size: 16px;
  font-weight: 500;
}
.template-coming-soon.layout-2 .site-main {
  padding: 0;
}
.template-coming-soon.layout-2 .cs-thumb {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  max-width: 50%;
  width: 100%;
}
.template-coming-soon.layout-2 .cs-thumb img {
  width: 100%;
  object-fit: cover;
}
.template-coming-soon.layout-2 .cs-day {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  left: 20px;
  right: 20px;
  text-align: center;
  height: auto;
  width: 180px;
  height: 180px;
  border-radius: 32px;
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  margin: 0 auto;
  background-color: rgba(255, 255, 255, 0.9);
  padding: 30px 0;
}
.template-coming-soon.layout-2 .cs-day span {
  font-family: 'Jost';
  font-size: 58px;
  color: #2d2d2d;
  font-weight: 700;
}
.template-coming-soon.layout-2 .cs-day p {
  font-family: 'Jost';
  font-size: 18px;
  font-weight: 500;
}
.field-group h3 {
  font-size: 18px;
  font-weight: 500;
  line-height: 1.44;
  margin-bottom: 20px;
}
.field-group label {
  font-size: 15px;
  font-weight: 500;
  display: block;
  font-family: 'Jost';
  color: #2d2d2d;
}
.ld-title {
  text-align: center;
  margin-top: 60px;
  margin-bottom: 40px;
}
.ld-title h2 {
  font-size: 48px;
  font-weight: 500;
  line-height: 1.6;
}
.ld-title p {
  font-size: 18px;
  line-height: 1.6;
}
.img-box-inner {
  margin-bottom: 80px;
}
.img-box-item {
  text-align: center;
  border-radius: 10px;
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  border: solid 1px #eeeeee;
  padding: 50px;
}
.img-box-item img {
  height: 72px;
  margin-bottom: 30px;
}
.img-box-item h3 {
  font-size: 24px;
  font-weight: 500;
  line-height: 1.58;
  margin-bottom: 10px;
}
.img-box-item p {
  font-size: 16px;
  line-height: 1.63;
}
.features-item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  align-items: center;
  margin-bottom: 30px;
}
.features-item .features-thumb {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 50%;
  flex: 0 0 50%;
  max-width: 50%;
  padding-right: 100px;
}
.features-item .features-info {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 50%;
  flex: 0 0 50%;
  max-width: 50%;
}
.features-item h3 {
  font-size: 64px;
  font-weight: 500;
  margin-bottom: 25px;
}
.features-item h3 span {
  color: #1d1d55;
}
.features-item p {
  font-size: 18px;
  line-height: 1.44;
  margin-bottom: 30px;
  max-width: 470px;
}
.features-item a.more {
  font-size: 15px;
  font-weight: 500;
  text-transform: uppercase;
  color: #1d1d55;
  font-family: 'Jost';
  position: relative;
}
.features-item a.more:after {
  content: '';
  width: 100%;
  height: 2px;
  display: block;
  background-color: #1d1d55;
  position: absolute;
  bottom: -2px;
  left: 0;
}
.features-item a.more:hover {
  opacity: 0.7;
}
.features-item:nth-child(2n-1) .features-thumb {
  order: 2;
  padding-right: 0;
  padding-left: 100px;
}
.features-item:nth-child(2n-1) .features-info {
  order: 1;
}
.features-inner {
  margin-bottom: 50px;
}
.home-header.home-header-while .site__menu__icon {
  color: #2d2d2d;
}
.home-header.home-header-while .right-header__destinations > a {
  color: #2d2d2d;
  font-family: 'Jost';
}
.home-header.home-header-while .right-header__login > a {
  color: #2d2d2d;
  font-family: 'Jost';
}
.home-header.home-header-while .right-header__signup > a {
  color: #2d2d2d;
  font-family: 'Jost';
}
.home-header.home-header-while .minicart__open > i {
  color: #2d2d2d;
}
.home-header.home-header-while .minicart__open > span {
  color: #1d1d55;
}
.home-header.home-header-while .right-header > div i {
  color: #5d5d5d;
}
.home-header.home-header-while .right-header__button {
  background-color: #1d1d55;
}
.home-header.home-header-while div.right-header__button i {
  color: #ffffff;
  transition: inherit;
  -moz-transition: inherit;
  -o-transition: inherit;
  -webkit-transition: inherit;
  -ms-transition: inherit;
}
.home-header.home-header-while div.right-header__button > a {
  color: #ffffff;
  transition: inherit;
  -moz-transition: inherit;
  -o-transition: inherit;
  -webkit-transition: inherit;
  -ms-transition: inherit;
}
.home-header.home-header-while div.right-header__button:hover {
  background-color: #ffffff;
}
.home-header.home-header-while div.right-header__button:hover i {
  color: #2d2d2d;
}
.home-header.home-header-while div.right-header__button:hover > a {
  color: #2d2d2d;
}
.site-banner__search.layout-02 {
  display: flex;
  border-radius: 30px;
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  background-color: #fff;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
  vertical-align: middle;
  align-items: center;
  position: relative;
  display: flex;
  background-color: #fff;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
  vertical-align: middle;
  align-items: center;
  position: relative;
  z-index: 9;
}
.site-banner__search.layout-02 .site-banner__search__input {
  box-shadow: inherit;
  padding: 0;
  color: #2d2d2d;
  font-family: 'Jost-Book';
  box-shadow: inherit;
  padding: 0;
  color: #2d2d2d;
  font-family: 'Jost-Book';
}
.site-banner__search.layout-02 .site-banner__search__input::placeholder {
  color: #8d8d8d;
  font-family: 'Jost-Book';
  color: #8d8d8d;
  font-family: 'Jost-Book';
}
.site-banner__search.layout-02
  .site-banner__search__input:-ms-input-placeholder {
  color: #8d8d8d;
  font-family: 'Jost-Book';
  color: #8d8d8d;
  font-family: 'Jost-Book';
}
.site-banner__search.layout-02
  .site-banner__search__input::-ms-input-placeholder {
  color: #8d8d8d;
  font-family: 'Jost-Book';
  color: #8d8d8d;
  font-family: 'Jost-Book';
}
.site-banner__search.layout-02 .field-input {
  display: flex;
  align-items: center;
  padding: 0 20px;
  position: relative;
  display: flex;
  align-items: center;
  padding: 0 20px;
  position: relative;
}
.site-banner__search.layout-02 .field-input label {
  color: #2d2d2d;
  font-family: 'Jost';
  margin-right: 8px;
  color: #2d2d2d;
  font-family: 'Jost';
  margin-right: 8px;
}
.site-banner__search.layout-02 .field-input input:focus {
  background-color: inherit !important;
  border-radius: 0;
}
.site-banner__search.layout-02 .field-input:nth-child(1) {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 55%;
  flex: 0 0 55%;
  max-width: 55%;
  position: relative;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 55%;
  flex: 0 0 55%;
  max-width: 55%;
  position: relative;
}
.site-banner__search.layout-02 .field-input:nth-child(1):after {
  content: '';
  width: 1px;
  position: absolute;
  top: 10px;
  bottom: 10px;
  right: 0;
  display: block;
  background-color: #eeeeee;
  content: '';
  width: 1px;
  position: absolute;
  top: 10px;
  bottom: 10px;
  right: 0;
  display: block;
  background-color: #eeeeee;
}
.site-banner__search.layout-02 .field-input:nth-child(2) {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 45%;
  flex: 0 0 45%;
  max-width: 45%;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 45%;
  flex: 0 0 45%;
  max-width: 45%;
}
.site-banner__search.layout-02
  .field-input:nth-child(2)
  .site-banner__search__input {
  padding: 0 45px 0 0;
  padding: 0 45px 0 0;
}
.site-banner__search.layout-02 .field-submit {
  margin-top: 0;
  height: 50px;
  position: absolute;
  top: 5px;
  right: 5px;
  margin-top: 0;
  height: 50px;
  position: absolute;
  top: 5px;
  right: 5px;
}
.site-banner__search.layout-02 .field-submit button {
  cursor: pointer;
  border: 0;
  background-color: #1d1d55;
  height: 50px;
  line-height: 50px;
  text-align: center;
  width: 50px;
  border-radius: 50%;
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  cursor: pointer;
}
.site-banner__search.layout-02 .field-submit button i {
  color: #ffffff;
  font-size: 22px;
  vertical-align: -2px;
  color: #ffffff;
  font-size: 22px;
  vertical-align: -2px;
}
.site-banner__search.layout-02 .field-submit button:focus {
  outline: inherit;
  outline: inherit;
}
.site-banner__search.layout-02 .search-suggestions {
  position: absolute;
  top: calc(100% + 2px);
  left: 0;
  right: 0;
  border-radius: 5px;
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.1);
  background-color: #ffffff;
  text-align: left;
  z-index: 99;
  display: none;
}
.site-banner__search.layout-02 .search-suggestions ul {
  padding: 7px 0;
  padding: 7px 0;
}
.site-banner__search.layout-02 .search-suggestions li {
  list-style: none;
  list-style: none;
}
.site-banner__search.layout-02 .search-suggestions li i {
  font-size: 24px;
  margin-right: 10px;
  vertical-align: -3px;
  font-size: 24px;
  margin-right: 10px;
  vertical-align: -3px;
}
.site-banner__search.layout-02 .search-suggestions li a {
  font-size: 16px;
  font-weight: 500;
  font-family: 'Jost';
  color: #2d2d2d;
  padding: 8px 25px;
  display: block;
  font-size: 16px;
  font-weight: 500;
  font-family: 'Jost';
  color: #2d2d2d;
  padding: 8px 25px;
  display: block;
}
.site-banner__search.layout-02 .search-suggestions li:hover a {
  background-color: #1d1d55;
  color: #ffffff;
  background-color: #1d1d55;
  color: #ffffff;
}
.bsn-cat-item a {
  width: 150px;
  height: 150px;
  border-radius: 50%;
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  display: block;
  text-align: center;
  padding-top: 35px;
  margin: 0 auto;
}
.bsn-cat-item a:hover {
  padding-top: 25px;
}
.bsn-cat-item a:hover span.place {
  opacity: 1;
  visibility: visible;
}
.bsn-cat-item i {
  font-size: 36px;
  color: #ffffff;
  display: block;
  margin-bottom: 10px;
}
.bsn-cat-item span.title {
  font-family: 'Jost';
  font-size: 16px;
  font-weight: 500;
  color: #ffffff;
  display: block;
  margin-bottom: 5px;
  line-height: 22px;
}
.bsn-cat-item span.place {
  font-size: 15px;
  color: #ffffff;
  opacity: 0;
  visibility: hidden;
  transition: all 0.3s ease-in-out 0s;
  -moz-transition: all 0.3s ease-in-out 0s;
  -o-transition: all 0.3s ease-in-out 0s;
  -webkit-transition: all 0.3s ease-in-out 0s;
  -ms-transition: all 0.3s ease-in-out 0s;
}
.bsn-cat-item.rosy-pink a {
  background-color: #f0626c;
}
.bsn-cat-item.purple a {
  background-color: #d763d7;
}
.bsn-cat-item.blue a {
  background-color: #5b5ff9;
}
.bsn-cat-item.orange a {
  background-color: #ffb44f;
}
.bsn-cat-item.charcoal-purple a {
  background-color: #846fcd;
}
.bsn-cat-item.green a {
  background-color: #78cc58;
}
.business-category {
  padding: 60px 0;
  position: relative;
  z-index: 2;
  background-color: #fff;
}
.testimonial-slider.layout-02 .testimonial-item {
  border-radius: 10px;
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  border: solid 1px #eeeeee;
  padding: 30px;
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  justify-content: space-between;
  text-align: left;
}
.testimonial-slider.layout-02 .testimonial-item img {
  position: static;
  -webkit-transform: inherit;
  -moz-transform: inherit;
  -o-transform: inherit;
  -ms-transform: inherit;
  transform: inherit;
  border-radius: 50%;
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  max-height: 150px;
  max-width: 150px;
}
.testimonial-slider.layout-02 .testimonial-item .avatar {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 150px;
  flex: 0 0 150px;
  max-width: 150px;
  position: relative;
}
.testimonial-slider.layout-02 .testimonial-item .avatar .quote {
  top: 10px;
  right: 0;
  left: auto;
  -webkit-transform: inherit;
  -moz-transform: inherit;
  -o-transform: inherit;
  -ms-transform: inherit;
  transform: inherit;
  position: absolute;
  width: auto;
  border-radius: 0%;
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  max-width: 28px;
}
.testimonial-slider.layout-02 .testimonial-item .testimonial-info {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 calc(100% - 180px);
  flex: 0 0 calc(100% - 180px);
  max-width: calc(100% - 180px);
}
.testimonial-slider.layout-02 .testimonial-item .testimonial-info p {
  font-size: 16px;
  line-height: 1.5;
  color: #5d5d5d;
  margin-bottom: 20px;
}
.testimonial-slider.layout-02 .testimonial-item .testimonial-info b {
  font-size: 16px;
  color: #2d2d2d;
}
.testimonial-slider.layout-02 .testimonial-item .testimonial-info span {
  font-size: 16px;
  color: #9a9a9a;
  font-family: 'Jost-Book';
  margin-left: 20px;
}
.business-cat-slider .bsn-cat-item {
  text-align: center;
}
.slick-dotted.slick-slider.business-cat-slider {
  margin-bottom: 0;
  margin-bottom: 40px;
}
.site__search.layout-02 {
  max-width: 500px;
}
.site__search.layout-02 .site-banner__search.layout-02 {
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.05);
  border: solid 1px #eeeeee;
  margin-bottom: 0;
}
.site__search.layout-02
  .site-banner__search.layout-02
  .site-banner__search__input {
  line-height: 50px;
}
.site__search.layout-02
  .site-banner__search.layout-02
  .site-banner__search__input::placeholder {
  opacity: 0;
}
.site__search.layout-02
  .site-banner__search.layout-02
  .site-banner__search__input:-ms-input-placeholder {
  opacity: 0;
}
.site__search.layout-02
  .site-banner__search.layout-02
  .site-banner__search__input::-ms-input-placeholder {
  opacity: 0;
}
.site__search.layout-02 .site-banner__search.layout-02 .field-submit button {
  height: 40px;
  width: 40px;
  line-height: 40px;
}
.filter.layout-02 {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  justify-content: space-between;
}
.filter-group.filter-business {
  padding: 25px 0;
  border-top: 1px solid #eeeeee;
}
.filter-group.filter-business .clear-filter {
  font-size: 15px;
  color: #1d1d55;
  font-family: 'Jost';
  display: none;
}
.owner-top {
  background-color: #ffeaec;
  padding: 25px 0;
}
.owner-top-inner {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.owner-top-inner a.btn {
  background-color: #2d2d2d;
  font-size: 15px;
}
.owner-top-inner a.btn i {
  margin-right: 10px;
  font-size: 24px;
  vertical-align: -2px;
}
.owner-top-info {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  align-items: center;
}
.owner-top-info .avatar {
  margin-right: 20px;
}
.owner-top-info .avatar img {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  object-fit: cover;
}
.owner-top-info .info h3 {
  font-size: 24px;
  font-weight: 500;
  margin-bottom: 8px;
}
.owner-top-info .info span {
  color: #2dcb48;
  font-size: 16px;
  margin-left: 5px;
}
.owner-top-info .info p {
  font-size: 16px;
}
.owner-page-wrap {
  padding: 40px 0 20px;
}
.owner-page-content h2 {
  font-size: 24px;
  font-weight: 500;
  margin-bottom: 25px;
}
.owner-page-content .pagination {
  text-align: left;
}
.flex {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  justify-content: space-between;
}
.features.layout-02 {
  padding: 40px 0;
  border-bottom: 1px solid #f5f8fd;
}
.features.layout-02 .features-item {
  margin-bottom: 0;
}
.features.layout-02 .features-item .features-content {
  margin-left: 25px;
  padding-right: 60px;
}
.features.layout-02 .features-item h3 {
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 4px;
}
.features.layout-02 .features-item p {
  font-size: 16px;
  margin: 0;
}
.list-categories {
  padding-top: 60px;
}
.list-categories h2 {
  font-size: 28px;
  font-weight: 500;
}
.list-categories .item {
  position: relative;
}
.list-categories .item > a {
  display: block;
  position: relative;
  overflow: hidden;
  border-radius: 10px;
}
.list-categories .item .info {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  right: 0;
  z-index: 2;
  text-align: center;
}
.list-categories .item .info span {
  font-size: 24px;
  font-weight: 500;
  color: #ffffff;
}
.list-categories .item .info img {
  position: absolute;
  bottom: 0;
  max-width: 140px;
  margin: 0 auto;
  top: 80%;
  left: 0;
  right: 0;
  z-index: -1;
}
.list-categories .item:hover .info img {
  transform: scale(1);
}
.title-wrap {
  margin-bottom: 40px;
}
.title-wrap h2 {
  font-size: 28px;
  font-weight: 500;
  margin-bottom: 10px;
}
.title-wrap p {
  font-size: 18px;
}
.list-categories.layout-02 .item {
  height: 240px;
  border-radius: 10px;
  background-position: right bottom;
  background-size: auto 240px;
  background-repeat: no-repeat;
  transition: all 0.3s;
}
.list-categories.layout-02 .item a {
  padding: 0 50px;
  width: 100%;
  height: 100%;
}
.list-categories.layout-02 .item .info {
  padding-right: 50%;
  left: 50px;
  text-align: left;
}
.list-categories.layout-02 .item .info > span {
  display: block;
  width: 100%;
}
.list-categories.layout-02 .item .title {
  font-family: 'Jost';
  opacity: 1;
  margin-bottom: 6px;
}
.list-categories.layout-02 .item .number {
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 30px;
}
.list-categories.layout-02 .item .btn {
  font-size: 15px;
  font-weight: 500;
  background-color: #2d2d2d;
}
.list-categories.layout-02 .item:hover {
  background-size: auto 260px;
}
.testimonial.layout-02 {
  background-color: #f1f6f6;
  padding: 60px 0;
}
.testimonial.layout-02 .slick-sliders {
  max-width: 100%;
}
.testimonial.layout-02 .slick-sliders .testimonial-item {
  background-color: #ffffff;
}
.footer {
  position: relative;
  z-index: 1;
  background-color: #ffffff;
}
.footer .footer__top__info .footer__top__info__logo img {
  margin-bottom: 40px;
}
.footer .footer__top__info .footer__top__info__desc {
  margin-bottom: 10px;
}
.footer {
  border-top: 1px solid #eeeeee;
}
.footer__bottom {
  border-top: 1px solid #eeeeee;
  padding: 32px 0;
}
.footer__top {
  padding: 60px 0;
}
.footer__top__info .footer__top__info__logo img {
  max-width: 200px;
  margin-bottom: 20px;
}
.footer__top__info .footer__top__info__desc {
  font-size: 16px;
  line-height: 1.44;
  color: #5d5d5d;
  margin-bottom: 25px;
}
.footer__top__info .footer__top__info__app a {
  display: inline-block;
  margin-right: 8px;
}
.footer__top__info .footer__top__info__app img {
  max-width: 134px;
}
.footer__top__nav h3 {
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 20px;
}
.footer__top__nav li {
  list-style: none;
  margin-bottom: 10px;
}
.footer__top__nav li:last-child {
  margin-bottom: 0;
}
.footer__top__nav li a {
  color: #5d5d5d;
  line-height: 1.53;
  font-weight: 500;
  font-family: 'Jost';
}
.footer__top__nav li a:hover {
  color: #1d1d55;
}
.footer__top__nav li i {
  font-size: 18px;
  line-height: 36px;
}
.footer__top__nav--contact p {
  font-weight: 500;
  font-family: 'Jost';
  line-height: 1.53;
  color: #5d5d5d;
  margin-bottom: 10px;
}
.footer__top__nav--contact ul {
  margin-top: 30px;
}
.footer__top__nav--contact li {
  display: inline-block;
  margin-right: 5px;
}
.footer__top__nav--contact li:last-child {
  margin-right: 0;
}
.footer__top__nav--contact li svg:hover > * {
  fill: #1d1d55;
}
.footer__top__nav--contact li a {
  display: block;
  width: 36px;
  height: 36px;
  text-align: center;
  line-height: 36px;
  border-radius: 5px;
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  color: #ffffff;
}
.footer__top__nav--contact li a:hover {
  color: #ffffff;
  opacity: 0.7;
}
.footer__top__nav--contact li.facebook a {
  background-color: #3b5998;
}
.footer__top__nav--contact li.twitter a {
  background-color: #1da1f2;
}
.footer__top__nav--contact li.youtube a {
  background-color: #cd201f;
}
.footer__top__nav--contact li.instagram a {
  background-color: #262626;
}
.footer__bottom__copyright {
  text-align: center;
  font-size: 14px;
  color: #5d5d5d;
}
