.trending1{
	background-color: #e7f2eb;
}

.site-banner .site-banner__content{
	top: 40%;
	left: 20%;
    right: 20px;
}

.site-banner{
	background-size: cover;
	background-position: center;
}

.site-banner__title,
.site-banner__meta span,
.site-banner__meta a{
	color: #fff;
}

.site-banner__meta a:after{
	background-color: #ededff;
}

@media(max-width: 576px){
	.site__brand__logo img {
	    max-width: 120px;
	}
	.site-banner .site-banner__content{
		padding-top: 100px;
	}
}
@media(min-width: 577px) and (max-width: 768px){
	.site__brand__logo img {
	    max-width: 160px;
	}
	.site-banner .site-banner__content{
		padding-top: 100px;
	}
}

.upgrade-btn:hover{
	opacity: 1 !important;
	color: #ec1c38 !important;
	background-color: #fff !important;
}

.right-header__button:hover{
	background-color: #ec1c38;
	opacity: 1;
}

.bsn-cat-item a{
	background-position: center;
	background-size: cover;
	background-repeat: no-repeat;
}