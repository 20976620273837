@media only screen and (max-width: 1500px) {
	.site__search.layout-02{
		max-width: 400px;
	}
	.site-banner__search.layout-02 .field-input:nth-child(1),
	.site-banner__search.layout-02 .field-input:nth-child(2){
		-webkit-box-flex: 0;
	    -ms-flex: 0 0 50%;
	    flex: 0 0 50%;
	    max-width: 50%;
	}
	.site__search.layout-02 .site-banner__search.layout-02 .field-input label{
		display: none !important;
	}
	.site__search.layout-02 .site-banner__search.layout-02 .site-banner__search__input::placeholder{
		opacity: 1;
		color: #2d2d2d;
    	font-family: "Jost";
	}
	.site__search.layout-02 .site-banner__search.layout-02 .site-banner__search__input:-ms-input-placeholder{
		opacity: 1;
		color: #2d2d2d;
    	font-family: "Jost";
	}
	.site__search.layout-02 .site-banner__search.layout-02 .site-banner__search__input::-ms-input-placeholder{
		opacity: 1;
		color: #2d2d2d;
    	font-family: "Jost";
	}
	.site__search.layout-02 .site-banner__search.layout-02 .search-suggestions li a{
		padding: 8px 10px;
	}
}
@media only screen and (min-width: 1200px) {
	.container{
		max-width: 1200px;
	}
}
@media only screen and (max-width: 1200px) {
	.site__search{
		max-width: 200px;
	}
	.right-header > div{
		margin-right: 18px;
	}
	.company-info>img {
	    -webkit-box-flex: 0;
	    -ms-flex: 0 0 50%;
	    flex: 0 0 50%;
	    max-width: 50%;
	}
	.page-title.layout-2:after{
		background-image: radial-gradient(circle at 91% 3%, transparent calc(400px + 1px), #23d3d3 calc(400px + 2px));
	}
	.member-place-list th,
	.member-place-list td{
		padding: 15px 10px;
	}
	.member-place-list.owner-booking td.place-action a{
		margin-bottom: 10px;
	}
	.main-primary .top-area-filter{
		flex-wrap: wrap;
	    justify-content: flex-end;
	    line-height: 40px;
	}
	.site__search.layout-02{
		max-width: 280px;
	}
	.site__search.layout-02 .site-banner__search.layout-02 .search-suggestions li a{
		padding: 2px 10px;
		font-size: 14px;
	}
}
@media only screen and (min-width: 992px) {
	.popup--left .popup__user,
	.popup--left .popup__destinations,
	.popup--left .popup__button{
		display: none;
	}
}
@media only screen and (max-width: 992px) {
	.right-header .right-header__destinations,
	.right-header .right-header__login,
	.right-header .right-header__signup,
	.right-header .right-header__button{
		display: none;
	}
	.right-header .right-header__minicart{
		margin-right: 0;
	}
	.right-header .right-header__search{
		display: inline-block;
		margin-right: 0;
	}
	.right-header__minicart .right-header__number{
		display: none;
	}
	.site__search{
		position: fixed;
		top: -100%;
		left: 0;
		right: 0;
		width: 100%;
		max-width: 100%;
		background-color: #fff;
		padding: 30px 55px 30px 25px;
		z-index: 9;
		transition: all 0.3s;
		opacity: 0;
		visibility: hidden;
	}
	.site__search.open{
		top: 0;
		opacity: 1;
		visibility: visible;
		transition: all 0.3s;
	}
	.site__search.open .search__close{
		display: block;
	}
	.site__search .site__search__form{
		border-bottom: 0;
		padding-bottom: 0;
	}
	.site__search .site__search__field{
		line-height: 0;
	}
	.site__search .site__search__field input{
		height: 20px;
		line-height: 20px;
	}
	.site-header .container-fluid{
		padding-left: 25px;
		padding-right: 25px;
	}
	.site-banner .site-banner__content{
		left: 25px;
		right: 25px;
	}
	.container{
		padding-left: 25px;
		padding-right: 25px;
	}
	.footer__top__info,
	.footer__top__nav{
		margin-bottom: 40px;
	}
	.footer__top__nav--contact{
		margin-bottom: 0;
	}
	.footer__top__nav--contact li{
		margin-bottom: 0;
	}
	.footer__top{
		padding: 60px 0 40px;
	}
	.page-title--small{
		background-position: center right -480px;
    	background-size: cover;
	}
	.place__amenities span{
		margin-left: 5px;
	}
	.company-info{
		display: block;
	}
	.company-info>img{
		-webkit-box-flex: 0;
	    -ms-flex: 0 0 100%;
	    flex: 0 0 100%;
	    max-width: 100%;
	    margin-bottom: 20px;
	}
	.company-info .ci-content{
		padding: 0;
	}
	.member-filter .field-select{
		max-width: 120px;
	}
	.home-header .site__menu__icon{
		display: inline-block !important;
		vertical-align: -4px;
	}
	.page-title.layout-2:before{
		content: '';
		display: none;
	}
	.page-title.layout-2 .page-title-info{
		max-width: 100%;
		text-align: center;
	}
	.page-title.layout-2 .page-title__content{
		text-align: center;
		margin-bottom: 60px;
	}
	.page-title.layout-2 .page-title-info{
		position: static;
	}
	.page-title.layout-2 .page-title-bottom{
		position: absolute;
		left: 0;
		right: 0;
		bottom: 0;
		margin-top: 0;
		padding: 25px 0;
		background-color: #23d3d3;
	}
	.right-header .right-header__search i{
		font-size: 24px;
	}
	.place-item.layout-02 .place-thumb img{
		width: 100%;
	}
	.main-primary{
		padding: 30px 15px;
		max-width: 720px;
		margin: 0 auto;
	}
	.main-primary .filter-control{
		display: none;
	}
	.archive-filter form{
		display: none;
	}
	.page-title.layout-2:after{
		content: '';
		display: none;
	}
	.field-check label {
	    -ms-flex: 0 0 33%;
	    flex: 0 0 33%;
	    max-width: 33%;
	}
	.template-coming-soon .footer{
		text-align: left;
	}
	.features-item h3{
		font-size: 48px;
	}
	.member-menu ul{
		width: 800px;
	}
	.member-menu > .container{
		overflow-x: scroll;
	}
	.member-place-list th,
	.member-place-list td,
	.member-place-list th:nth-child(1),
	.member-place-list td:nth-child(1){
		padding: 15px 10px;
	}
	.member-place-list td:nth-child(4){
		width: 20%;
	}
	.member-statistical{
		margin-bottom: 0;
	}
	.member-statistical .item,
	.ob-item{
		margin-bottom: 30px;
	}
	.right-header__languages ul{
		right: 0;
		left: auto;
		width: 140px;
	}
	.bsn-cat-item span.place{
		opacity: 1;
		visibility: visible;
	}
	.bsn-cat-item a{
		padding-top: 25px;
	}
	.site__search.layout-02.open{
		max-width: 100%;
		padding: 14px 60px 14px 30px;
	}
	.main-primary .top-area .type{
		text-align: right;
	}
	.right-header .main-menu{
		display: none;
	}
	.banner.slick-sliders .slick-slide>div{
		height: 100%;
	}
	a.site__menu__icon{
		display: block;
	}
	.single .place{
		position: relative;
		z-index: 2;
	}
	.main-primary .top-area-filter .filter-right{
		display: none;
	}
	.main-primary .top-area-filter{
		justify-content: space-between;
	}
	.list-categories.layout-02 .item{
		margin-bottom: 30px;
	}
	.features.layout-02 .features-item{
		flex-wrap: wrap;
		text-align: center;
		margin-bottom: 30px;
	}
	.features.layout-02 .features-item img{
		margin: 0 auto 20px;
	}
	.features.layout-02 .features-item .features-content{
		width: 100%;
		margin: 0;
		padding: 0;
	}
	.features.layout-02 .features-item p{
		max-width: 100%;
	}
	.features.layout-02{
		padding: 40px 0 0;
	}
	.list-categories .item{
		margin-bottom: 30px;
	}
}
@media only screen and (max-width: 767px) {
	.banner-apps{
		padding: 60px 0 120px;
		background-position: left 0 center;
	}
	.banner-apps__content{
		padding-left: 0;
		text-align: center;
	}
	.banner-apps__content .banner-apps__download > a{
		display: block;
		margin-right: 0;
		margin: 0 auto 20px;
	}
	.banner-apps__content .banner-apps__download > a:last-child{
		margin-right: auto;
		margin-bottom: 0;
	}
	.place__amenities{
		margin-bottom: 20px;
	}
	.place-item__photo{
		top: 30px;
		left: 50px;
		right: auto;
		bottom: auto;
	}
	.page-title.page-title--small{
		background-image: none !important;
	}
	.place-03 .breadcrumbs{
		margin-bottom: 30px;
	}
	.grid.grid-4{
		flex-wrap: wrap;
	}
	.grid.grid-4 .grid-item{
		-webkit-box-flex: 0;
	    -ms-flex: 0 0 50%;
	    flex: 0 0 50%;
	    max-width: 50%;
	    margin-bottom: 30px;
	}
	.our-team{
		padding: 30px 0 0;
	}
	.join-team{
		padding-bottom: 30px;
	}
	.site-contact:before{
		display: none;
	}
	.contact-main .contact-form{
		padding: 0;
		margin-bottom: 30px;
	}
	.contact-main .contact-text{
		padding: 30px 0 0;
	}
	.table-responsive,
  	.table-responsive thead,
  	.table-responsive tbody,
  	.table-responsive th,
  	.table-responsive td,
  	.table-responsive tr {
    	display: block;
  	}
  	.table-responsive > thead > tr {
   	 	position: absolute;
    	top: -9999px;
    	left: -9999px;
  	}
  	.table-responsive > tbody > tr {
    	border-top: 1px solid #eee;
    	border-bottom: 1px solid #eee;
  	}
  	.table-responsive > tbody > tr:nth-child(2n) td{
		background-color: rgba(35,211,211, 0.1);
  	}
  	.table-responsive > tbody > tr:first-child {
    	border-radius: 3px 3px 0 0;
    	border-top: none;
  	}
  	.table-responsive > tbody > tr:last-child {
    	border-radius: 0 0 3px 3px;
    	border-bottom: none;
  	}
  	.table-responsive > tbody > tr td {
	    border: none;
	    border-bottom: 1px solid #eee;
	    position: relative;
	    padding-left: 30% !important;
	    width: 100%;
	    overflow: hidden;
	    text-align: right;
  	}
  	.table-responsive > tbody > tr td .field-check{
  		display: block;
  		margin: 0;
  	}
  	.table-responsive > tbody > tr td .field-check label{
  		-webkit-box-flex: 0;
	    -ms-flex: 0 0 100%;
	    flex: 0 0 100%;
	    max-width: 100%;
	    padding: 0;
  	}
  	.table-responsive > tbody > tr td .field-check label:before{
		margin-right: 0;
  	}
  	.table-responsive > tbody > tr td .field-check label input:checked ~ span{
		left: 0;
  	}
  	.table-responsive > tbody > tr td:before {
	    content: attr(data-title);
	    position: absolute;
	    top: 15px;
	    left: 14px;
	    width: 30%;
	    padding-right: 10px;
	    white-space: nowrap;
	    font-size: 14px;
	    text-align: left;
  	}
  	.table-responsive.table-order > tbody > tr:nth-child(-n + 3) > td:first-child {
	    padding: 25px 0 25px 30% !important;
	    background-position: left 32% center;
  	}
  	.table-responsive.table-order > tbody > tr:nth-child(-n + 3) > td:first-child span {
    	left: 32%;
  	}
  	.member-filter{
  		display: block;
  	}
  	.member-filter .mf-left{
  		-webkit-box-flex: 0;
	    -ms-flex: 0 0 100%;
	    flex: 0 0 100%;
	    max-width: 100%;
  	}
  	.member-filter .field-select{
  		max-width: 100%;
  		margin: 0 0 20px 0;
  	}
  	.member-filter .mf-right form{
  		width: 100%;
  	}
  	.member-place-list td:nth-child(1),
  	.member-place-list td:nth-child(2),
  	.member-place-list td:nth-child(3),
  	.member-place-list td:nth-child(4){
  		width: 100%;
  	}
  	.table-responsive > tbody > tr td:last-child,
  	.table-responsive > tbody > tr{
  		border-bottom: 0;
  	}
  	.similar-places__content .place-item{
  		margin-bottom: 30px;
  	}
  	.place__comments__content .row{
  		display: none;
  	}
  	.home-main .site-banner__content h1{
  		font-size: 42px;
  	}
  	.testimonial .slick-sliders{
  		padding: 0 30px;
  	}
  	.testimonial .slick-nav > div{
  		left: -25px;
  	}
  	.testimonial .slick-nav .slick-nav__next{
  		right: -25px;
  	}
  	.home-main .site-banner:after{
  		height: 60px;
  	}
  	.listing-main .listing-nav .listing-menu li a span.icon {
	    display: block;
	    font-size: 21px;
	}
	.listing-main .listing-nav .listing-menu li a span {
	    display: none;
	}
	.listing-main .listing-nav {
	    position: -webkit-sticky;
	    position: -webkit-sticky;
	    top: 0;
	    padding: 10px;
	    flex: 0 0 40px;
	    max-width: 40px;
	}
	.listing-main .listing-content {
	    max-width: 100%;
	    -webkit-box-flex: 0;
	    -ms-flex: 0 0 94%;
	    flex: 0 0 94%;
	    max-width: 94%;
	    padding: 30px 20px;
	}
	.listing-main .listing-nav .listing-menu li.active a,
	.listing-main .listing-nav .listing-menu li a:hover {
	    border: 0;
	    color: #23d3d3;
	}
	.listing-main .listing-nav .listing-menu li a {
	    border: 0;
	}
	.listing-main .listing-nav .listing-menu li {
	    text-align: center;
	}
	.site-header {
	    position: relative;
	    z-index: 111;
	}
	.site-header.home-header{
		position: absolute;
	}
	.template-coming-soon .site-header,
	.template-coming-soon .footer{
		position: static;
	}
	.template-coming-soon .site-main{
		height: 100%;
		padding: 30px 0;
	}
	.template-coming-soon .site-main .cs-info{
		position: static;
		-webkit-transform: inherit;
	    -moz-transform: inherit;
	    -o-transform: inherit;
	    -ms-transform: inherit;
	    transform: inherit;
	}
	.template-coming-soon.layout-2 .cs-thumb{
		position: static;
		max-width: 100%;
	}
	.template-coming-soon .site-main .cs-thumb img{
		height: auto;
	}
	.img-box-item{
		margin-bottom: 30px;
	}
	.img-box-inner{
		margin-bottom: 0;
	}
	.features-item h3{
		font-size: 32px;
	}
	.member-wrap-top{
		flex-wrap: wrap;
	}
	.member-wrap-top h2{
		-webkit-box-flex: 0;
	    -ms-flex: 0 0 100%;
	    flex: 0 0 100%;
	    max-width: 100%;
	    margin-bottom: 10px;
	}
	.member-place-wrap .pagination{
		margin-bottom: 0;
	}
	.upgrade-box img{
		display: none;
	}
	.upgrade-box{
		padding: 30px;
	}
	.upgrade-box .close {
		right: 15px;
		top: 15px;
	}
	.owner-content{
		padding-bottom: 0;
	}
	.member-place-list.owner-booking td:nth-child(2),
	.member-place-list.owner-booking td:nth-child(3),
	.member-place-list.owner-booking td:nth-child(4){
		width: 100%;
	}
	.site__search.layout-02.open{
		padding: 14px 60px 14px 15px;
	}
	.banner-left,
	.banner.slick-sliders{
		width: 50%;
	}
	.single-02 .place__left{
		padding-right: 0;
	}
	.single-slider.slick-sliders img{
		height: 300px;
	}
	.testimonial .slick-sliders{
		max-width: 100%;
		padding: 0;
	}
	.single .place-gallery{
		top: 30px;
		left: 50px;
		bottom: auto;
		right: auto;
	}
	.single .place-gallery a{
		font-size: 0;
		height: 48px;
		width: 48px;
		border-radius: 50%;
		line-height: 48px;
		padding: 0;
		text-align: center;
	}
	.single .place-gallery i{
		font-size: 24px;
		vertical-align: -7px;
	}
}
@media only screen and (max-width: 575px) {
	.site__order > div{
		margin-right: 5px;
	}
	.site__order > div > a{
		font-size: 14px;
	}
	.place-flex .place__currency{
		margin-right: 15px;
	}
	.place-03 .breadcrumbs{
		margin-bottom: 15px;
	}
	.place__left h1{
		font-size: 32px;
	}
	.place__box.place__box--npd{
		text-align: center;
	}
	.place__box.place__box--npd > h1,
	.place__box.place__box--npd > div{
		text-align: left;
	}
	.grid.grid-4{
		margin-left: -5px;
		margin-right: -5px;
	}
	.grid.grid-4 .grid-item{
		padding-left: 5px;
		padding-right: 5px;
	}
	.error-wrap{
		margin: 0 auto 40px;
	}
	.member-wrap .member-avatar label input{
		width: 100px;
	}
	.account span{
		display: none;
	}
	.site-header .container-fluid{
		padding-left: 15px;
		padding-right: 15px;
	}
	.site-header .container-fluid .row{
		margin-left: -5px;
		margin-right: -5px;
	}
	.site-header .row .col-7,
	.site-header .row .col-5{
		padding-left: 5px;
		padding-right: 5px;
		-webkit-box-flex: 0;
	    -ms-flex: 0 0 50%;
	    flex: 0 0 50%;
	    max-width: 50%;
	}
	.site__brand{
		margin-right: 0;
	}
	.right-header .right-header__search{
		margin-right: 18px;
	}
	.mw-box .places-item{
		margin-bottom: 0;
	}
	.popup-form.open{
		top: 20px;
	}
	.other-products__content .col-sm-6,
	.other-city__content .col-md-6{
	    -webkit-box-flex: 0;
	    -ms-flex: 0 0 50%;
	    flex: 0 0 50%;
	    max-width: 50%;
	    padding-left: 5px;
	    padding-right: 5px;
	}
	.similar-places__content .col-md-6,
	.city-slider .slick-slide>div{
		padding-left: 5px;
		padding-right: 5px;
	}
	.similar-places__content .row{
		margin-left: -5px;
		margin-right: -5px;
	}
	.places-item{
		margin-bottom: 30px;
	}
	.home-main .site-banner:after{
		height: 40px;
	}
	.home-main .site-banner__content p,
	.testimonial-item p{
		font-size: 16px;
	}
	.place-slider__nav{
		display: none;
	}
	.title-border-bottom{
		font-size: 24px;
	}
	.trending,
	.testimonial{
		padding: 30px 0 40px;
	}
	.testimonial .slick-sliders{
		padding: 0;
	}
	.testimonial-control .tc-item{
		padding: 10px 7px;
	}
	.testimonial-control .slick-current img{
		margin-top: 0;
	}
	.testimonial-control{
		height: auto;
	}
	.testimonial-control .tc-item{
		padding: 10px 4px;
	}
	.page-title.layout-2 .page-title-bottom{
		overflow-x: auto;
	}
	.popup-wrap .popup-middle{
		top: 30px;
		bottom: 30px;
		transform: inherit;
	    -moz-transform: inherit;
	    -ms-transform: inherit;
	    -webkit-transform: inherit;
	    -o-transform: inherit;
	    overflow-y: auto;
	    overflow-x: hidden;
	}
	.listing-main .listing-content {
	    max-width: 100%;
	    -webkit-box-flex: 0;
	    -ms-flex: 0 0 calc(100% - 40px);
	    flex: 0 0 calc(100% - 40px);
	    max-width: calc(100% - 40px);
	    padding: 30px 0px;
	    background-color: #fff;
	}
	.listing-content > h2{
		font-size: 18px;
	    margin-bottom: 10px;
	    padding: 0 15px;
	}
	.field-check{
		margin-bottom: 0;
	}
	.field-check label {
	    -webkit-box-flex: 0 !important;
	    -ms-flex: 0 0 50% !important;
	    flex: 0 0 50% !important;
	    max-width: 50% !important;
	}
	.upload-form .field-submit{
		padding: 0 15px;
	}
	.template-coming-soon .cs-info h1{
		font-size: 36px;
	}
	.template-coming-soon .site-header{
		padding-top: 30px;
	}
	.template-coming-soon .footer{
		padding-bottom: 30px;
	}
	.template-coming-soon.layout-2 .site-main{
		padding: 10px 0;
	}
	.ld-title{
		margin-bottom: 30px;
	}
	.ld-title h2{
		font-size: 32px;
		margin-bottom: 0;
	}
	.ld-title p{
		font-size: 16px;
	}
	.features-item{
		flex-wrap: wrap;
	}
	.features-item .features-thumb,
	.features-item .features-info{
		-webkit-box-flex: 0;
	    -ms-flex: 0 0 100%;
	    flex: 0 0 100%;
	    max-width: 100%;
	    padding-right: 0;
	}
	.features-item:nth-child(2n-1) .features-thumb{
		order: 1;
		padding-left: 0px;
	}
	.upgrade-box h1{
		font-size: 24px;
		line-height: normal;
	}
	.upgrade-box p{
		font-size: 16px;
	}
	.member-statistical .item{
		padding: 20px 10px;
	}
	.member-statistical .item h3{
		font-size: 14px;
	}
	.testimonial-item.layout-02{
	    display: block !important;
	    padding: 20px;
	}
	.testimonial-item.layout-02 .avatar{
		-webkit-box-flex: 0;
	    -ms-flex: 0 0 100%;
	    flex: 0 0 100%;
	    max-width: 100%;
	    width: 150px;
	    margin: 0 auto;
	}
	.testimonial-item.layout-02 .testimonial-info{
		-webkit-box-flex: 0;
	    -ms-flex: 0 0 100%;
	    flex: 0 0 100%;
	    max-width: 100%;
	    margin-top: 30px;
	    text-align: center;
	}
	.site-banner__search.layout-02 .field-input label{
		display: none;
	}
	.site-banner__search.layout-02 .field-input:nth-child(1){
		-webkit-box-flex: 0;
	    -ms-flex: 0 0 50%;
	    flex: 0 0 50%;
	    max-width: 50%;
	}
	.site-banner__search.layout-02 .field-input:nth-child(2){
		-webkit-box-flex: 0;
	    -ms-flex: 0 0 50%;
	    flex: 0 0 50%;
	    max-width: 50%;
	}
	.site-banner__search.layout-02 .field-input{
		position: static !important;
	}
	.site-banner__search.layout-02 .field-input:nth-child(1):after{
		right: 50%;
	}
	.owner-top-inner,
	.owner-top-info{
		flex-wrap: wrap;
		text-align: center;
	}
	.owner-top-info{
		width: 100%;
		margin-bottom: 20px;
	}
	.owner-top-button{
		margin: 0 auto;
	}
	.owner-top-info .avatar{
		margin: 0 auto;
		width: 100%;
		margin-bottom: 10px;
	}
	.owner-top-info .info{
		width: 100%;
	}
	.banner.slick-sliders .slick-slide > div,
	.banner.slick-sliders img{
		height: 350px;
	}
	.banner-left:after{
		height: auto;
		top: -37%;
	}
	.banner.slick-sliders .slick-dots{
		top: 40%;
	}
	.testimonials{
		padding: 30px 0;
	}
	.similar-places{
		padding: 30px 0 0;
	}
	.title h2{
		font-size: 20px;
	}
	.testimonial-slider .slick-dots{
		margin-top: 10px;
	}
	.home-testimonials .testimonial-item{
		display: block;
		text-align: center;
	}
	.home-testimonials .testimonial-item .testimonial-thumb{
		margin: 0 auto;
	}
	.home-testimonials .testimonial-item .testimonial-info{
		width: 100%;
		margin-top: 30px;
		margin-left: 0;
		text-align: center;
	}
	.menu-item{
		width: 100%;
	}
	.single-02 .place__box--npd h1{
		font-size: 28px;
	}
	.menu-tab li{
		margin-right: 10px;
	}
	.menu-tab li a{
		font-size: 14px;
	}
	.single .popup-wrap .hightlight-flex .place__amenities{
		-webkit-box-flex: 0;
	    -ms-flex: 0 0 50%;
	    flex: 0 0 50%;
	    max-width: 50%;
	}
	.purchase-button{
		left: 20px;
		bottom: 20px;
	}
	.button-wrap{
		margin-top: 0;
	}
	.testimonial-control .tc-item img{
		width: 46px;
		height: 46px;
	}
	.slick-slider img{
		height: 260px;
	}
	.slick-slider .testimonial-item img{
		height: auto;
	}
	.single .hightlight-grid{
		width: 576px;
	}
	.single .place-gallery{
		top: 20px;
		left: 25px;
	}
	.single-01 .place__left{
		padding-right: 0;
		margin-top: -140px;
	}
	.site-banner{
		background-size: 550px;
		background-position: right -208px bottom;
	}
	.single .open-table .time{
		padding: 0 0 0 20px;
	}
	.field-group .field-clone{
		margin-bottom: 10px;
	}
	.main-primary .top-area-filter .filter-center{
		display: none;
	}
	.main-primary .top-area-filter{
		justify-content: flex-end;
	}
	.site-banner.layout-02{
		background-image: inherit !important;
		height: auto;
    	padding: 120px 0 100px;
	}
	.site-banner.layout-02 .site-banner__title{
		font-size: 32px;
	}
	.site-banner.layout-02 .site-banner__title:after{
		content: '';
		display: none;
	}
	.site-banner .site-banner__content{
		position: static;
		transform: inherit;
	}
}
@media only screen and (max-width: 375px) {
	.places-item .places-item__thumb img{
		width: 100%;
	}
	.site-banner .site-banner__content{
		text-align: center;
		top: 60px;
		transform: inherit;
	}
	.site-banner__meta a{
		font-weight: 400;
		font-family: "Jost-Book";
	}
	.footer__top__info .footer__top__info__app a,
	.footer__top__info{
		margin-bottom: 20px;
	}
	.grid.grid-5 .grid-item,
	.grid.grid-4 .grid-item,
	.grid.grid-3 .grid-item,
	.grid.grid-2 .grid-item,
	.city-grid .col-6 {
	    -webkit-box-flex: 0;
	    -ms-flex: 0 0 100%;
	    flex: 0 0 100%;
	    max-width: 100%;
	}
	.place__amenities span{
		margin-left: 2px;
	}
	.place__comments li .place__author__avatar{
		margin-bottom: 10px;
	}
	.mw-box{
		margin-bottom: 0;
	}
	.popup-form{
		width: auto;
	}
	.popup-form.open{
		top: 15px;
	}
	.site-banner .site-banner__content{
		top: 50%;
		transform: translateY(-50%);
	}
	.checkout-thanks-area h2{
		font-size: 22px;
		margin-bottom: 20px;
	}
	.page-title--small{
		margin-bottom: 30px;
	}
	.checkout-thanks-area h3{
		font-size: 18px;
	}
	.payment-method img{
		height: 30px;
	}
	.site__menu>a{
		margin-right: 10px;
	}
	.bsn-cat-item a{
		width: 130px;
		height: 130px;
		padding-top: 15px;
	}
	.testimonial-item .testimonial-info .cite h4,
	.testimonial-item .testimonial-info .cite span{
		display: block;
		margin: 0;
	}
	.testimonial-item .testimonial-info .cite span:before{
		content: '';
		display: none;
	}
	.single-slider.slick-sliders img{
		height: 220px;
	}
	.menu-tab li a{
		font-size: 12px;
	}
	.home-testimonials .testimonial-item .testimonial-info .cite span:before{
		display: none;
	}
	.site-main.home-main .testimonial{
		padding-bottom: 0;
	}
	..site-banner .site-banner__content{
		transform: inherit;
	}
	.list-categories.layout-02 .item .info{
		left: 30px;
		right: 30px;
		padding-right: 0;
	}
	.title-wrap{
		margin-bottom: 20px;
	}
	.title-wrap h2{
		font-size: 20px;
	}
	.title-wrap p{
		font-size: 15px;
	}
	.testimonial-slider.layout-02 .testimonial-item{
		flex-wrap: wrap;
	}
	.testimonial-slider.layout-02 .testimonial-item .avatar,
	.testimonial-slider.layout-02 .testimonial-item .testimonial-info{
		-webkit-box-flex: 0;
	    -ms-flex: 0 0 100%;
	    flex: 0 0 100%;
	    max-width: 100%;
	}
	.testimonial-slider.layout-02 .testimonial-item img{
		margin: 0 auto 20px;
	}
	.testimonial-slider.layout-02 .slick-dots,
	.testimonial-slider .slick-dots{
		margin-top: 30px;
	}
}