.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.fixed-top-omni {
  position: fixed;
  top: 0;
  /* right: 0; */
  /* left: 0; */
  z-index: 1030;
}

.fixed-top-omni-mobile {
  position: fixed;
  top: 0;
  /* right: 0; */
  left: 0;
  z-index: 1030;
}

.padding-omni {
  padding-right: 500px;
}

.main-menu-omni {
  letter-spacing: normal;
  list-style: none;
  position: relative;
  /* line-height: 40px; */
  margin-right: 25px;
  font-family: 'Jost';
}

.label-omni {
  color: #2d2d2d;
  font-family: 'Jost';
  margin-left: 20px;
  margin-right: 8px;
}

.search-button-omni {
  cursor: pointer;
  border: 0;
  background-color: #1d1d55;
  height: 50px;
  line-height: 50px;
  text-align: center;
  width: 50px;
  border-radius: 50%;
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -ms-backface-visibility: hidden;
  -o-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  cursor: pointer;
}
.search-button-omni i {
  color: #ffffff;
  font-size: 22px;
  vertical-align: -2px;
  color: #ffffff;
  font-size: 22px;
  vertical-align: -2px;
}
.search-button-omni:focus {
  outline: inherit;
  outline: inherit;
}

.auth-form {
  max-width: 360px;
  margin: 60px auto;
  padding: 40px;
  border: 1px solid #ddd;
  box-shadow: 3px 3px 5px rgba(0, 0, 0, 0.05);
  background: #fff;
}
