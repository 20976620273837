.btn {
  border-radius: 10px;
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  background-color: 'green';
  height: 30px;
  line-height: 20px;
  font-weight: 500;
  padding: 0 25px;
  color: #ffffff;
  outline: 0;
  outline-offset: 0;
}
.btn:hover {
  color: #ffffff;
  opacity: 0.7;
}
.btn:focus {
  box-shadow: none;
  color: #ffffff;
}
